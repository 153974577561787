.leaflet-container {
  height: 100% !important;
  width: 100% !important;
  background: var(--neutral-color) !important;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  color: var(--neutral-color) !important;
  background: var(--primary-color) !important;
}
.leaflet-popup-content {
  margin: 16px 12px 16px 0 !important;
}

.leaflet-tooltip {
  color: var(--neutral-color) !important;
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  padding: 8px !important;
  padding-left: 0 !important;
}
.leaflet-tooltip-left:before {
  border-left-color: var(--primary-color) !important;
}
.leaflet-tooltip-right:before {
  border-right-color: var(--primary-color) !important;
}

.leaflet-tooltip-top:before {
  border-top-color: var(--primary-color) !important;
}
.leaflet-tooltip-bottom:before {
  border-bottom-color: var(--primary-color) !important;
}

.leaflet-marker-icon {
  background: var(--primary-color) !important;
  border-radius: 50% !important;
  &:hover {
    background: var(--secondary-color) !important;
  }
}
