.privacy {
  @include container-width(1126px);
  @include flex;
  @include flex-wrap;
  padding: 16px 0 24px;

  &-content {
    padding: 0 16px;
    h3,
    p {
      line-height: 1.7;
    }

    strong {
      display: inline-block;
      padding-bottom: 8px;
    }

    h3 {
      padding: 16px 0 8px;
    }

    p {
      padding-bottom: 8px;
    }

    a {
      color: var(--secondary-color);
      &:hover {
        color: var(--secondary-600-color);
        text-decoration: underline;
      }
    }

    ul {
      li {
        font-size: 0.875rem;
        padding: 4px 0;
      }
    }
    ol {
      li::marker {
        font-weight: 700;
      }
    }
  }

  @media screen and (min-width: 769px) {
    padding: 32px 0;

    h1 {
      font-size: 2.125rem;
    }

    ul {
      li {
        font-size: 1rem;
      }
    }
  }
}
