@font-face {
  font-family: 'Barlow';
  src: url('../../fonts/Barlow-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow-Medium';
  src: url('../../fonts/Barlow-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow-Semibold';
  src: url('../../fonts/Barlow-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow-Black';
  src: url('../../fonts/Barlow-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Icon Font
@font-face {
  font-family: 'icon-font';
  src: url('../../fonts/icon-font-viprfp.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  &::before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon-font' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon-edit-alt::before {
  content: '\e91a';
}
.icon-icon-bookmark::before {
  content: '\e91b';
}
.icon-trash::before {
  content: '\e91c';
}
.icon-delete::before {
  content: '\e91d';
}
.icon-show::before {
  content: '\e91e';
}
.icon-hide::before {
  content: '\e91f';
}
.icon-lock::before {
  content: '\e920';
}
.icon-plus::before {
  content: '\e921';
}
.icon-minus::before {
  content: '\e922';
}
.icon-move::before {
  content: '\e923';
}
.icon-link-external::before {
  content: '\e924';
}
.icon-info-filled-circle::before {
  content: '\e925';
}
.icon-info-circle::before {
  content: '\e926';
}
.icon-error-circle::before {
  content: '\e927';
}
.icon-check-circle::before {
  content: '\e928';
}
.icon-warning::before {
  content: '\e929';
}
.icon-heart::before {
  content: '\e92a';
}
.icon-file-blank::before {
  content: '\e92b';
}
.icon-file-pdf::before {
  content: '\e92c';
}
.icon-pdf::before {
  content: '\e92d';
}
.icon-share::before {
  content: '\e92e';
}
.icon-filled-envelope::before {
  content: '\e92f';
}
.icon-filter::before {
  content: '\e930';
}
.icon-half-star::before {
  content: '\e931';
}
.icon-export::before {
  content: '\e932';
}
.icon-download::before {
  content: '\e933';
}
.icon-cloud-upload::before {
  content: '\e934';
}
.icon-details::before {
  content: '\e935';
}
.icon-capsule::before {
  content: '\e936';
}
.icon-credit-card::before {
  content: '\e937';
}
.icon-partners::before {
  content: '\e919';
}
.icon-checked::before {
  content: '\e916';
}
.icon-filled-user::before {
  content: '\e917';
}
.icon-verified::before {
  content: '\e918';
}
.icon-phone::before {
  content: '\e915';
}
.icon-calendar-check::before {
  content: '\e90e';
}
.icon-edit::before {
  content: '\e90f';
}
.icon-envelope::before {
  content: '\e910';
}
.icon-filled-star::before {
  content: '\e911';
}
.icon-map-pin::before {
  content: '\e912';
}
.icon-notification::before {
  content: '\e913';
}
.icon-user::before {
  content: '\e914';
}

.icon-broken-link .path1::before {
  content: '\e90c';
}
.icon-broken-link .path2::before {
  content: '\e90d';
  margin-left: -1em;
}

.icon-close::before {
  content: '\e90a';
}
.icon-chevron-down::before {
  content: '\e90b';
}
.icon-arrow-dropdown::before {
  content: '\e900';
}
.icon-arrow-right::before {
  content: '\e901';
}
.icon-bookmark::before {
  content: '\e902';
}
.icon-chevron-double-right::before {
  content: '\e903';
}
.icon-facebook::before {
  content: '\e904';
}
.icon-instagram::before {
  content: '\e905';
}
.icon-linkedin::before {
  content: '\e906';
}
.icon-search::before {
  content: '\e907';
}
.icon-star::before {
  content: '\e908';
}
.icon-tag::before {
  content: '\e909';
}
