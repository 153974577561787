.footer {
  min-height: 200px;
  background: var(--primary-color);
  padding: 40px 16px 0;

  @media screen and (min-width: 1025px) {
    padding: 40px 24px 0;
  }

  &__wrapper {
    @include container-width(1126px);
    text-align: center;

    @media screen and (min-width: 1025px) {
      text-align: left;
    }
  }

  &__logo {
    img {
      width: 68px;
      height: 24px;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }

  &__nav-wrapper {
    @include space-between;
    @include flex-align-center;
    padding: 8px 0 24px;
    border-bottom: 1px solid var(--neutral-500-color);

    @media screen and (min-width: 1025px) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }

  &__nav {
    color: var(--neutral-color);
    padding: 24px 0;

    > li {
      @include transition(color 0.2s ease-out);
      position: relative;
      display: block;
      min-height: 44px;
      font-family: 'Barlow-Medium';
      font-size: 0.875rem;
      text-transform: uppercase;
      padding: 12px 0;
      cursor: pointer;

      &:hover {
        color: var(--secondary-color);
      }

      > a {
        font-family: inherit;
        color: inherit;

        &.active {
          font-family: 'Barlow-Semibold';
        }
      }
    }

    &-subnav {
      @include transition(var(--transition-default));
      width: calc(100% + 32px);
      height: 0;
      background: var(--color-footer-submenu);
      margin: 0 -16px;
      overflow: hidden;

      &.open {
        height: 320px;
        margin-top: 8px;
      }

      li {
        @include transition(var(--transition-default));
        display: block;
        color: var(--neutral-color);
        line-height: 40px;
        text-transform: none;
        white-space: nowrap;
        padding: 0 16px;
        cursor: pointer;

        a {
          display: block;
          font-family: 'Barlow';
          color: var(--neutral-color);
        }

        &::before {
          display: none;
        }

        &:hover {
          background: var(--primary-color);
        }
      }

      @media screen and (min-width: 769px) {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: auto;

        &.open {
          height: 320px;
        }

        li {
          border: none;
        }
      }
    }

    @media screen and (min-width: 769px) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      > li {
        &:not(:first-child) {
          padding-left: 24px;
        }
      }
    }

    @media screen and (min-width: 1025px) {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0;

      li {
        min-height: auto;
      }
    }
  }

  &__buttons-wrapper {
    > button {
      margin: 0 12px;
    }

    @media screen and (min-width: 1025px) {
      > button {
        margin-left: 24px;
      }
    }
  }

  &__bottom {
    @include space-between;
    @include flex-align-center;
    color: var(--neutral-500-color);
    padding: 16px 0;

    > div:last-child {
      margin-bottom: 24px;
    }

    @media screen and (min-width: 769px) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      > div:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__bottom-nav {
    @include flex;
    @include space-between;
    order: 1;
    margin: 8px 0 24px;

    a {
      font-family: 'Barlow-Medium';
      color: var(--neutral-500-color);
      font-size: 0.875rem;
      text-transform: uppercase;

      &:hover {
        color: var(--secondary-color);
      }
    }

    @media screen and (min-width: 769px) {
      display: block;
      margin: 0;

      a:last-child {
        margin-left: 48px;
      }
    }
  }
}
