.profile-logo-empty-state {
  flex: 0 0 auto;
  display: inline-block;
  width: 96px;
  height: 96px;
  margin-right: 16px;

  @media screen and (min-width: 1001px) {
    width: 140px;
    height: 140px;
  }
}

.profile-cover-empty-state {
  width: 100%;
  padding-bottom: 113%;
}

.landscape-empty-state {
  padding-bottom: 50%;
}

.key-partner-empty-state {
  padding-bottom: 126%;
}

.portrait-empty-state {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.industry-leaders-logo-empty-state {
  width: 64px;
  height: 64px;
  background: var(--neutral-200-color);

  @media screen and (min-width: 769px) {
    width: 100px;
    height: 100px;
  }
}

.bordered {
  background: var(--neutral-100-color);
  border: 2px dashed var(--neutral-400-color);

  &.absolute {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.no-search-results {
  text-align: center;
  padding: 40px 0;

  img {
    width: 56px;
    -o-object-fit: contain;
    object-fit: contain;
  }

  p {
    padding: 16px 0;
  }
}
