@mixin max-width($property) {
  width: $property;
}

.how-it-works-main {
  @include flex;
  @include flex-wrap;
  @include flex-align-center;
  @include container-width(1440px);
  height: auto;
  background: var(--neutral-color);
  padding-bottom: 24px;

  > div:first-child {
    padding: 24px 16px;
    background: var(--primary-color);

    * {
      color: var(--neutral-color);
    }

    h1 {
      font-size: 2.125rem;
      padding-bottom: 16px;
    }
  }

  > div:last-child {
    width: 100%;
    text-align: center;
    padding: 24px 16px 0;

    img {
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 769px) {
    padding: 64px 0;
    > div:first-child {
      width: 37%;
      padding: 64px;
    }
    > div:last-child {
      width: 63%;
      padding: 0;
    }
  }

  @media screen and (min-width: 1151px) {
    > div:first-child {
      padding: 64px 88px 64px 120px;
    }
  }
}

.how-it-works-content {
  padding: 0 16px;
}

.how-it-works-details {
  @include container-width(1126px);
  padding-top: 40px;

  @media screen and (min-width: 769px) {
    padding-top: 80px;
  }

  @media screen and (min-width: 1151px) {
    background: url('../../assets/landing/curve.svg') no-repeat left;
    background-position: 4px 276px;
    padding-top: 164px;
  }
}

.flex-double-wrapper {
  @include flex;
  @include flex-wrap;
  @include flex-align-center;
  margin-bottom: 40px;

  > div {
    width: 100%;
  }

  h2 {
    line-height: 1.4;
    padding: 8px 0;
  }

  p {
    font-size: 1rem;
  }

  @media screen and (min-width: 769px) {
    margin-bottom: 120px;
    > div {
      width: 50%;

      &.order-1 {
        order: 1;
      }

      &.order-2 {
        order: 2;
      }
      &.order-1:last-child,
      &:first-child {
        padding-right: 0.75rem;
        padding-left: 0;
      }
      &.order-2:first-child,
      &:last-child {
        padding-left: 0.75rem;
        padding-right: 0;
      }
    }
  }

  @media screen and (min-width: 1151px) {
    margin-bottom: 180px;
    &.mb-324 {
      margin-bottom: 324px;
    }
  }
}

.square-sign {
  width: 40px;
  height: 40px;
  font-family: 'Barlow-Semibold';
  font-size: 1.25rem;
  text-align: center;
  line-height: 40px;
  background: var(--neutral-300-color);
  border-radius: 4px;
}

.white-card {
  @include box-shadow(var(--box-shadow-default));
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: var(--neutral-color);
  border-radius: 2px;
  padding: 16px;
  margin: 16px 0;

  &.size {
    &-small {
      width: 240px;
    }
    &-xsmall {
      width: 136px;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  @media screen and (min-width: 769px) {
    &.size {
      &-standard {
        width: 282px;
      }
      &-medium {
        width: 377px;
      }

      &-large {
        width: 456px;
      }

      &-small {
        width: 310px;
      }

      &-xsmall {
        width: 172px;
      }
    }
  }

  .icon-verified {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 1rem;
    &::before {
      font-size: 1.2rem;
      margin-right: 8px;
      vertical-align: middle;
    }
  }

  &__heading {
    @include flex;
    @include space-between;
    font-size: 0.875rem;
    padding: 4px 8px 8px;

    > span:first-child {
      font-family: 'Barlow-Medium';
      text-transform: uppercase;
    }
  }

  &__category-list {
    padding: 0 8px 8px;
    li {
      font-size: 0.875rem;
      line-height: 40px;

      &::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 2px;
        background: var(--neutral-200-color);
        margin-right: 8px;
        vertical-align: middle;
      }

      &:first-child,
      &:nth-child(3),
      &:nth-child(4) {
        &::before {
          font-family: 'icon-font' !important;
          content: '\e916';
          color: var(--neutral-color);
          font-size: 0.5rem;
          line-height: 18px;
          text-align: center;
          font-weight: 600;
          background: var(--primary-color);
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--neutral-200-color);
      }
    }
  }

  &__wrapper {
    font-family: 'Barlow-Semibold';
    font-size: 0.8rem;
    line-height: 2;
    &::before {
      font-size: 0.6rem;
      margin-right: 8px;
    }

    @media screen and (min-width: 769px) {
      font-size: 1rem;
      padding: 0 16px;

      &::before {
        font-size: 0.8rem;
      }
    }
  }

  &__empty-item-img {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__small-title {
    font-family: 'Barlow-Semibold';
    font-size: 1rem;
    text-align: center;
    padding-bottom: 16px;

    &.icon-partners {
      border-bottom: 1px solid;
      padding-bottom: 8px;
      margin-bottom: 16px;

      &::before {
        margin-right: 8px;
      }
    }
  }

  &__wish-list-item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .icon-filled-star {
      position: absolute;
      top: 50%;
      right: 0;
      font-size: 0.8rem;

      &::before {
        font-size: 1rem;
        vertical-align: -2px;
        margin-right: 8px;
      }
    }

    img {
      max-width: 60%;
    }

    @media screen and (min-width: 769px) {
      img {
        max-width: 100%;
      }
      .icon-filled-star {
        font-size: 1rem;

        &::before {
          font-size: 1.2rem;
        }
      }
    }
  }

  .review-rating {
    @include flex;
    @include flex-align-center;
    position: absolute;
    top: 16px;
    right: 16px;

    h3 {
      margin-right: 8px;
    }

    .icon-filled-star::before {
      font-size: 1rem;
      vertical-align: bottom;
    }
  }

  .bottom-arrow {
    position: absolute;
    bottom: -6px;

    &.left {
      left: 12px;
    }
    &.right {
      right: 12px;
    }
    @media screen and (min-width: 769px) {
      &.left {
        left: 16px;
      }
      &.right {
        right: 16px;
      }
    }
  }

  .user-pick {
    position: absolute;
    bottom: -50px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: var(--neutral-color);
    font-size: 0.875rem;
    text-align: center;
    border-radius: 50%;
    background: var(--primary-color);

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    @media screen and (min-width: 769px) {
      bottom: -58px;
      width: 48px;
      height: 48px;
      font-size: 1rem;
      line-height: 48px;
    }
  }
}

.message-block {
  width: 288px;
  margin: 0 auto;

  > div {
    margin-bottom: 64px;
    &:first-child {
      margin-left: auto;
    }
    &:last-child {
      margin-right: auto;
    }
  }

  @media screen and (min-width: 769px) {
    width: 438px;
    margin: 0;
    > div {
      margin-top: 64px;
      margin-bottom: 0;
    }
  }
}

.partnership-block {
  @include flex;
  @include justify-center;

  > div {
    &:first-child,
    &:last-child {
      margin-top: 20%;
    }
  }

  @media screen and (min-width: 481px) {
    > div {
      &:first-child,
      &:last-child {
        margin-top: 20%;
      }
    }
  }
}

.how-it-works--text {
  text-align: center;
  background: var(--neutral-color);
  padding: 16px;

  > p {
    max-width: 770px;
    margin: 0 auto;
  }
}
