@-webkit-keyframes inner {
  from {
    @include transform(translate3D(0, -64px, 1px));
  }
  to {
    @include transform(translate3D(0, 0, 1px));
  }
}

@keyframes inner {
  from {
    @include transform(translate3D(0, -64px, 1px));
  }
  to {
    @include transform(translate3D(0, 0, 1px));
  }
}

.header {
  @include animation(inner 0.3s backwards);
  @include animation-delay(0.5s);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: var(--neutral-color);
  border-bottom: 1px solid var(--neutral-200-color);
  padding: 0 24px;
  z-index: 3;

  &__wrapper {
    @include flex;
    @include space-between;
    @include container-width(1126px);
  }

  &__logo {
    line-height: 62px;
    img {
      width: 68px;
      height: 24px;
      vertical-align: middle;
    }
  }

  &__nav-wrapper {
    @include transition(var(--transition-default));
    @include transform(translateX(100%));
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--neutral-color);

    &.menu-open {
      @include transform(translateX(0));
    }

    nav {
      @include flex;
      @include flex-column;
      max-height: 100%;
      overflow-y: auto;
    }

    @media screen and (min-width: 1025px) {
      position: static;
      @include transform(translateX(0));

      nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        overflow: unset;
      }
    }
  }

  &__nav {
    @include flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    @media screen and (min-width: 1025px) {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    > .btn-logout {
      margin: 24px auto 0;
    }

    .profile-info {
      @include flex;
      text-transform: none;
      padding: 16px 0;

      &__img {
        width: 56px;
        height: 56px;
        -o-object-fit: contain;
        object-fit: contain;

        &.empty {
          background: var(--neutral-300-color);
        }
      }

      &__name {
        @include flex;
        @include flex-column;
        @include justify-center;
        width: calc(100% - 56px); // 56px - width of .profile-info__img
        padding-left: 16px;

        &:hover {
          color: var(--primary-color);
        }

        > div {
          font-size: 0.875rem;
          line-height: 1.7;
        }

        .name {
          font-family: 'Barlow-Semibold';
          word-break: break-word;
        }

        .email {
          color: var(--neutral-500-color);
        }
      }
    }

    > li {
      position: relative;
      min-height: 44px;
      font-family: 'Barlow-Medium';
      font-size: 0.875rem;
      color: var(--primary-color);
      line-height: 44px;
      text-transform: uppercase;
      border-bottom: 1px solid var(--neutral-200-color);
      padding: 0 16px;
      cursor: pointer;

      &:hover {
        color: var(--secondary-color);
      }

      > a {
        @include transition(var(--transition-default));
        display: inline-block;
        font-family: inherit;
        color: inherit;
        text-align: center;

        &::after {
          @include transition(width 0.2s ease-out);
          display: block;
          content: '';
          width: 0;
          height: 4px;
          background-color: var(--secondary-color);
          margin: -2px auto 0;
        }

        &.active {
          font-family: 'Barlow-Semibold';
          &::after {
            width: 100%;
          }
        }
      }

      &.tab {
        > div::after {
          @include transition(width 0.2s ease-out);
          display: block;
          content: '';
          width: 0;
          height: 4px;
          background-color: var(--secondary-color);
          margin: -6px auto 0;
        }
        &.active {
          > div::after {
            width: 100%;
          }
        }
      }

      @media screen and (min-width: 1025px) {
        height: 62px;
        font-size: 0.7rem;
        line-height: 62px;
        border: none;
        padding: 0 12px;
      }

      @media screen and (min-width: 1201px) {
        font-size: 0.875rem;
      }
    }
    &-subnav {
      @include transition(var(--transition-default));
      width: calc(100% + 32px);
      height: 0;
      background: var(--neutral-100-color);
      margin: 0 -16px;
      overflow: hidden;

      &.open {
        height: 360px;
      }

      li {
        @include transition(var(--transition-default));
        position: relative;
        display: block;
        font-family: 'Barlow';
        color: var(--primary-color);
        line-height: 44px;
        text-transform: none;
        white-space: nowrap;
        border-top: 1px solid var(--neutral-200-color);
        padding: 0 42px 0 16px;
        cursor: pointer;

        a {
          display: block;
          color: var(--primary-color);
        }

        &::before {
          @include transition(var(--transition-default));
          position: absolute;
          right: 0;
          width: 40px;
          height: 100%;
          text-align: center;
          line-height: inherit;
          opacity: 0;
        }

        &:hover {
          background: var(--neutral-100-color);
          &::before {
            opacity: 1;
          }
        }
      }

      @media screen and (min-width: 1025px) {
        position: absolute;
        top: 103%;
        left: 0;
        width: auto;
        border-color: var(--neutral-200-color);
        background: var(--neutral-color);

        &.open {
          height: 354px;
          border: 1px solid var(--neutral-200-color);
        }

        li {
          border: none;
        }
      }
    }
  }

  &__login {
    @include flex;
    @include flex-column;
    padding: 24px 16px;

    button {
      width: 100%;
      margin-bottom: 16px;
    }

    a.login {
      order: 1;
      font-family: 'Barlow-Medium';
      font-size: 0.875rem;
      color: var(--primary-color);
      text-align: center;
      text-transform: uppercase;
      text-decoration: underline;
      line-height: 44px;
      &:hover {
        color: var(--secondary-color);
      }

      @media screen and (min-width: 1201px) {
        font-size: 0.875rem;
      }
    }

    @media screen and (min-width: 481px) {
      button {
        width: auto;
        margin-right: auto;
      }
      a.login {
        text-align: left;
      }
    }

    @media screen and (min-width: 1025px) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      padding: 8px 0;

      a.login {
        order: 0;
        font-size: 0.8rem;
        text-align: center;
        margin: 0 24px;
      }

      button {
        margin-bottom: 0;
        margin-right: 0;
      }
    }

    @media screen and (min-width: 1201px) {
      padding-left: 40px;
    }
  }

  a.icon-icon-bookmark {
    position: absolute;
    top: 12px;
    right: 104px;
    display: inline-block;
    width: 24px;
    height: 46px;
    font-size: 1.1rem;
    text-align: center;
    color: var(--primary-color);
    line-height: 44px;
    margin-left: 16px;

    &:hover {
      color: var(--secondary-color);
    }

    @media screen and (min-width: 1025px) {
      position: static;
      width: auto;
    }
  }
}
