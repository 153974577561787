.settings {
  width: 100%;
  min-height: calc(100vh - 264px);
  background: var(--neutral-color);
  padding: 0 24px;

  &__content {
    @include flex;
    @include flex-wrap;
    @include container-width(1126px);
    padding: 24px 0;

    h1 {
      width: 100%;
    }
    &-column {
      > a {
        margin-top: 24px;
      }
      @media screen and (min-width: 901px) {
        width: 50%;
      }
    }
  }

  form {
    position: relative;
    width: 100%;
    padding: 16px 0 24px;
    margin: 0;

    @media screen and (min-width: 481px) {
      width: 360px;
    }

    h3 {
      padding-bottom: 8px;
    }

    .btn-primary {
      width: 100%;

      @media screen and (min-width: 521px) {
        width: 80px;

        &.absolute {
          position: absolute;
          right: -96px;
          bottom: 40px;
        }
      }
    }
  }

  &__logo-wrapper {
    position: relative;
    width: 166px;
    height: 166px;
    margin-bottom: 16px;

    img {
      width: 166px;
      height: 166px;
      object-fit: contain;
    }
  }
}

.description-list {
  dd {
    button {
      width: 100%;

      @media screen and (min-width: 417px) {
        width: auto;
      }
    }

    a {
      display: inline-block;
    }
  }
}

.subscription-block {
  @media screen and (min-width: 701px) {
    padding-left: 24px;
  }
}
