.action-btns-wrapper {
  @include flex;
  @include justify-center;
  @include transition(var(--transition-default));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 16px;
  opacity: 0;
  pointer-events: none;

  > button,
  label {
    margin: 0 4px;
    cursor: pointer;
  }
}

.action-btns-touch-devices {
  display: none;

  @media (hover: none) and (pointer: coarse) and (max-width: 1000px) {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 16px;

    > button,
    label {
      margin: 0 4px;
    }
  }
}

.edit-profile {
  @extend .profile;

  &__back-to-profile-wrapper {
    @extend .profile__info-wrapper;
    padding: 10px 16px;

    > div {
      @include flex;
      @include flex-align-center;
      @include container-width(1126px);
    }
  }

  &__main-info-wrapper {
    @extend .profile__main-info-wrapper;
  }

  &__main-info {
    @extend .profile__main-info;

    &--heading-logo {
      width: 166px;
      height: 166px;
      object-fit: contain;
    }
  }

  &__cover-image-wrapper {
    @extend .profile__cover-image-wrapper;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 113%;

    img {
      position: absolute;
    }

    &:hover {
      .action-btns-wrapper {
        opacity: 1;
        pointer-events: auto;
      }
    }

    @media screen and (min-width: 1001px) {
      padding-bottom: 47.3%;
    }
  }

  &__upload-image {
    @include flex;
    @include flex-column;
    @include justify-center;
    @include flex-align-center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--neutral-100-color);
    border: 2px dashed var(--neutral-400-color);

    p {
      text-align: center;
      padding: 16px 16px 0;
      cursor: default;
    }
  }

  &__grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: 0.785rem;
    padding: 16px 0;

    @media screen and (min-width: 769px) {
      -ms-grid-columns: minmax(0, 1fr) 0.785rem minmax(0, 1fr);
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 0.785rem;
      grid-template-rows: repeat(2, minmax(0, 2fr));
      grid-template-areas:
        'cell-1 cell-2'
        'cell-3 cell-2';

      .cell-1 {
        grid-area: cell-1;
      }
      .cell-2 {
        grid-area: cell-2;
      }
      .cell-3 {
        grid-area: cell-3;
      }
    }

    > div {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    }

    .cell-3 {
      > div {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: (minmax(0, 1fr));
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-row-gap: 0.785rem;

        @media screen and (min-width: 1201px) {
          -ms-grid-columns: minmax(0, 1fr) 0.785rem minmax(0, 1fr);
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-column-gap: 0.785rem;
        }
      }
    }
  }

  &__company-logo-wrapper {
    position: relative;
    width: 166px;
    height: 166px;
    margin-bottom: 16px;
  }

  &__upload-portfolio-label {
    display: inline-block;
    padding: 16px 0;

    @media screen and (min-width: 1201px) {
      padding: 0;
    }

    span {
      @include transition(var(--transition-default));
      display: inline-block;
      font-family: 'Barlow-Medium';
      font-size: 0.785rem;
      text-transform: uppercase;
      line-height: 42px;
      border-radius: 2px;
      border: 1px solid var(--neutral-300-color);
      padding: 0 22px;
      cursor: pointer;

      &::before {
        font-size: 1.2rem;
        margin-right: 8px;
        vertical-align: -2px;
      }

      &:hover {
        color: var(--secondary-color);
        background: var(--secondary-S15-color);
        border-color: var(--secondary-color);
      }
    }

    p {
      padding-top: 8px;
      cursor: default;
    }
  }

  section {
    padding-top: 40px;

    h5 {
      padding: 16px 0;
    }

    p {
      padding: 16px 0;
    }
  }

  &__projects-wrapper {
    @extend .profile__projects-wrapper;
  }
  &__projects {
    @extend .profile__projects;

    > div {
      padding: 0 16px;

      * {
        margin-bottom: 16px;
      }
    }

    @media screen and (min-width: 769px) {
      > div {
        width: 100%;
      }
    }
  }

  &__upload-wide-wrapper {
    position: relative;
    padding-bottom: 50%;
  }

  &__image-block {
    @extend .profile__image-block;

    section {
      @include container-width(1126px);
      padding: 40px 16px 0;
    }
  }

  &__partners-block {
    padding: 0 16px;

    > div {
      @include container-width(1126px);

      &:first-child {
        padding-bottom: 40px;
      }
    }
  }

  &__submit-btn-wrapper {
    @include flex;
    @include display-flex-end;
    @include container-width(1126px);
    padding: 32px 16px 0;

    > button {
      width: 100%;
    }

    @media screen and (min-width: 415px) {
      > button {
        width: auto;
        padding: 0 80px;
      }
    }

    @media screen and (min-width: 1151px) {
      padding: 40px 0 0;
    }
  }
}

.upload-image-label {
  @include transition(color 0.2s ease-out);
  font-family: 'Barlow-Medium';
  font-size: 0.875rem;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: var(--secondary-color);
  }

  span {
    text-decoration: underline;
  }

  .icon-cloud-upload {
    text-decoration: none;
    font-size: 2rem;
    margin-bottom: 4px;
  }
}

.two-columns-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-row-gap: 0.785rem;

  @media screen and (min-width: 769px) {
    -ms-grid-columns: minmax(0, 1fr) 0.785rem minmax(0, 1fr);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 0.785rem;
  }
}

.logo-action-btns-wrapper {
  @include flex;
  @include flex-column;
  @include justify-center;
  position: absolute;
  right: -104px;
  top: 0;
  bottom: 0;
  padding-left: 16px;

  > button,
  > label {
    margin: 8px 0;
    cursor: pointer;
  }
}

.editable-list {
  &.margin-top {
    margin-top: 16px;
  }

  li {
    position: relative;
    height: 56px;
    line-height: 1.2;
    border-radius: 2px;
    border: 1px solid var(--neutral-300-color);
    padding: 16px 64px 16px 16px;
    margin-bottom: 16px;

    &.icon {
      padding-left: 32px;
    }

    span {
      @include ellipsis-text;
      display: inline-block;
      font-size: 1rem;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 4px;
      bottom: 0;
      width: 32px;
      text-align: center;
      line-height: 54px;
      font-size: 1rem;
    }

    .btns-action-icon-wrapper {
      position: absolute;
      top: 0;
      right: 8px;
      bottom: 0;

      button {
        height: 54px;
        padding: 0;
        margin: 0 4px;
      }
    }
  }

  &.two-columns-grid {
    li:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.input__autocomplete {
  position: absolute;
  top: 100%;
  right: auto;
  left: 0;
  width: 100%;
  display: none;
  pointer-events: none;

  &.open {
    @include box-shadow(var(--box-shadow-default));
    display: block;
    height: 200px;
    background: var(--neutral-color);
    padding: 16px;
    border-radius: 4px;
    border: 1px solid var(--neutral-200-color);
    overflow-y: auto;
    pointer-events: auto;
    z-index: 1;
  }

  li {
    line-height: 1.2;
    padding: 8px 0;
    cursor: pointer;

    &.active {
      font-family: 'Barlow-Medium';
    }

    &.active,
    &:hover {
      color: var(--secondary-color);
    }
  }
}

.tags-container {
  @include flex;
  @include flex-wrap;

  .simple-tag {
    position: relative;
    font-family: 'Barlow-Medium';
    font-size: 0.875rem;
    text-transform: uppercase;
    line-height: 1.3;
    padding: 8px 40px 8px 16px;
    border-radius: 24px;
    border: 1px solid var(--primary-color);
    margin: 0 8px 8px 0;

    span {
      word-break: break-word;
    }

    button {
      @include transform(translateY(-50%));
      position: absolute;
      right: 8px;
      top: 50%;
      width: 24px;
      height: 20px;
      font-size: 0.7rem;
      padding: 0;
      margin-left: 8px;
    }
  }
}

.projects-on-map-list {
  width: 100%;
  background: var(--neutral-100-color);
  padding-left: 16px;

  li {
    position: relative;
    border-bottom: 1px solid var(--neutral-300-color);
    padding: 16px 64px 16px 0;
    margin: 0;

    .item {
      @include ellipsis-text;
      margin-bottom: 0;
    }

    .btns-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;

      > button {
        height: 100%;
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 1001px) {
    height: 408px;
    overflow-y: auto;
  }
}

.position-relative {
  position: relative;

  .action-btns-wrapper {
    position: static;
    opacity: 1;
    pointer-events: auto;
    background: var(--neutral-color);
  }

  .btn-view-all {
    position: absolute;
    left: 112px;
    bottom: 0;
  }

  @media screen and (min-width: 769px) {
    .action-btns-wrapper {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      background: transparent;
    }
    &:hover {
      .action-btns-wrapper {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
