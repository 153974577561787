.partner-with-us {
  @include flex;
  @include flex-column;
  @include justify-center;
  @include flex-align-center;
  width: 100%;
  min-height: calc(100vh - 264px);
  max-width: 584px;
  padding: 48px 16px;
  margin: 0 auto;

  &__block {
    position: relative;
    padding-right: 32px;
    cursor: pointer;

    * {
      padding: 8px 0;
    }

    .icon-arrow-right {
      @include transform(translateY(-50%));
      position: absolute;
      top: 50%;
      right: 0;
      color: var(--primary-color);
      padding: 0;
    }

    &:hover {
      .icon-arrow-right {
        color: var(--secondary-color);
      }
    }

    @media screen and (min-width: 769px) {
      padding: 8px 80px 8px 16px;

      .icon-arrow-right {
        right: 32px;
      }
    }
  }

  .white-card {
    @include transition(var(--transition-default));
    margin-bottom: 24px;

    &:hover {
      @include box-shadow(var(--box-shadow-hover));
    }

    h3 {
      color: var(--primary-color);
    }
  }
}

.join-form {
  &.white-card {
    padding: 0;
  }

  &__heading {
    line-height: 1.7;
    border-bottom: 1px solid var(--neutral-300-color);
    padding: 32px 32px 16px 32px;
  }

  &__content {
    padding: 16px 32px;
  }

  &__footer {
    button,
    a {
      width: 100%;
      margin-bottom: 24px;
    }

    .to-login {
      text-align: center;
      padding-bottom: 8px;

      > span,
      > a {
        font-family: 'Barlow-Medium';
        color: inherit;
        text-decoration: underline;
        padding-left: 8px;
        white-space: nowrap;

        &:hover {
          color: var(--secondary-color);
        }
      }
    }
  }
}

.message {
  text-align: right;
}
