.subscription {
  width: 100%;
  padding-top: 24px;

  > h1,
  > p {
    padding: 0 16px 16px;
  }

  > p {
    color: var(--neutral-500-color);
  }

  @media screen and (min-width: 769px) {
    @include flex;
    @include flex-column;
    @include justify-center;
    @include flex-align-center;
    width: 100%;
    max-width: 584px;
    min-height: calc(100vh - 264px);
    padding-bottom: 32px;
    margin: 0 auto;

    > p {
      color: var(--primary-color);
    }
  }

  &-content {
    background: var(--neutral-color);
    padding: 16px;

    @media screen and (min-width: 769px) {
      @include box-shadow(var(--box-shadow-default));
      position: relative;
      width: 100%;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      border-radius: 2px;
      padding: 32px;
    }
  }

  .offers-list {
    padding: 16px 0 24px;

    li {
      position: relative;
      line-height: 1.7;
      padding: 8px 0 8px 40px;
      border-top: 1px solid var(--neutral-300-color);

      &::before {
        position: absolute;
        left: 4px;
        top: 22px;
        font-size: 0.6rem;
        color: var(--secondary-color);
      }

      &:last-child {
        border-bottom: 1px solid var(--neutral-300-color);
      }

      @media screen and (min-width: 769px) {
        line-height: 1.95;
      }
    }
  }

  .billing-frequency {
    @include flex;
    @include flex-wrap;
    @include space-between;
    padding: 8px 0 16px;

    label:nth-child(2) {
      order: 2;
    }
  }

  &-btn-wrapper {
    border-top: 1px solid var(--neutral-300-color);
    padding-top: 16px;

    > button {
      width: 100%;
      margin: 16px 0;
    }

    @media screen and (min-width: 481px) {
      @include flex;
      @include space-between;
      @include flex-align-center;

      > button {
        width: auto;
      }
    }
  }

  .icon {
    width: 56px;
    height: auto;
    -o-object-fit: contain;
    object-fit: contain;
    margin-bottom: 8px;
  }
}
