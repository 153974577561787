*,
::after,
::before {
  box-sizing: border-box;
}
:root {
  -moz-tab-size: 4;
  tab-size: 4;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-color);
  background: var(--neutral-100-color);
  margin: 0;
}
b,
strong {
  font-weight: 600;
}
small {
  font-size: 80%;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.2;
  margin: 0;
}
button,
select {
  text-transform: none;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  padding: 0;
}
progress {
  vertical-align: baseline;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-size: 100%;
}
body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
}
img {
  max-width: 100%;
  height: auto;
}
input,
select,
textarea {
  font-size: 16px;
}
button,
input[type='submit'],
label[for] {
  cursor: pointer;
}
[role='button'],
a,
button,
input,
label,
select,
textarea {
  touch-action: manipulation;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;

  &.style-disc {
    list-style: disc;
    margin-left: 24px;
  }
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Barlow-Semibold';
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  padding: 0;
  margin: 0;
}
h1 {
  font-size: 1.25rem;

  @media screen and (min-width: 769px) {
    font-size: 1.75rem;
  }
}

h3 {
  font-size: 1rem;

  @media screen and (min-width: 769px) {
    font-size: 1.25rem;
  }
}

h4 {
  display: inline-block;
  font-family: 'Barlow-Medium';
  font-size: 0.875rem;
}

h5 {
  font-size: 1rem;
  color: var(--neutral-500-color);
  text-transform: none;
}

p {
  font-size: 0.875rem;
  line-height: 1.4;
  padding: 0;
  margin: 0;

  @media screen and (min-width: 769px) {
    font-size: 1rem;

    &.small {
      font-size: 0.875rem;
    }
  }
}

.gray-text {
  color: var(--neutral-500-color);
}

.text-right {
  text-align: right;
}

.success {
  color: var(--secondary-color);
}

.error {
  height: 18px;
  color: var(--error-500-color);

  &.desktop-absolute {
    @media screen and (min-width: 521px) {
      position: absolute;
      left: 0;
      bottom: -20px;
    }
  }

  &.height-auto {
    height: auto;
  }
}

.page-content {
  padding-top: 64px;
  min-height: calc(100vh - 200px);
}

.overflow-hidden {
  overflow: hidden;
}
.p-16 {
  padding: 16px;
}
.p-0-16 {
  padding: 0 16px;
}
.p-16-0 {
  padding: 16px 0;
}

.p-24 {
  padding: 24px;
}

.p-b-24 {
  padding-bottom: 24px;
}
.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}

.max-width-480 {
  max-width: 480px;
}

.simple-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.hidden-card {
  display: inline-block;
  width: 1px;
  height: 1px;
  vertical-align: top;

  &.full {
    height: 100%;
  }
}

.uppercase {
  text-transform: uppercase;
}

.simple-link {
  font-family: 'Barlow-Medium';
  font-size: 0.875rem;
  color: var(--primary-color);
  text-transform: uppercase;
  text-decoration: underline;

  &:hover {
    color: var(--secondary-color);
  }
}

.description-list {
  padding-bottom: 20px;
  margin: 0;

  dt,
  dd {
    font-size: 1rem;
    margin: 0;
  }

  dt {
    color: var(--neutral-500-color);
    line-height: 1.2;
  }
  dd {
    padding: 4px 0;
    line-height: 1.7;
    a {
      display: block;
    }
    button {
      margin: 16px 24px 0 0;

      &::before {
        font-size: 1.2rem;
        margin-right: 8px;
        vertical-align: bottom;
      }
    }
  }
}

.white-bg {
  width: 100%;
  min-height: calc(100vh - 264px);
  background: var(--neutral-color);
}
