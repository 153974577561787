.notifications {
  @include container-width(1150px);
  padding: 32px 16px;

  h1 {
    padding-bottom: 24px;
  }

  @media screen and (min-width: 481px) {
    padding: 32px 24px;
  }
}

.notification-block {
  @include flex;
  @include flex-wrap;
  padding: 16px;
  border-radius: 2px;
  border: 1px solid var(--neutral-300-color);

  &__image {
    width: 56px;
    height: 56px;

    img,
    > div {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
    > div {
      background: var(--neutral-300-color);
    }
  }

  .gray-text {
    font-size: 0.875rem;
  }

  &__details,
  &__info {
    @include flex;
  }

  &__details {
    @include space-between;
    @include align-flex-end;
    width: 100%;
    padding-top: 16px;
  }

  &__info {
    @include flex-column;
    @include space-around;
    width: calc(100% - 56px); // 56px - width of &__image
    padding: 0 16px;

    h4 {
      @include ellipsis-text;
      font-family: 'Barlow-Semibold';
    }
    p {
      word-break: break-word;
    }
  }

  @media screen and (min-width: 481px) {
    padding: 24px 32px;

    &__details {
      @include flex-column;
      @include space-around;
      @include align-flex-end;
      width: 96px;
      text-align: right;
      padding-top: 0;
    }

    &__info {
      width: calc(100% - 152px);
      // 96px - width of &__details, 56px - width of &__image
      padding: 0 16px;
    }
  }
}
