.icon-chevron-down.btn {
  @extend .btn-minimal;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  line-height: 42px;
  font-size: 0.5rem;
  pointer-events: none;
  cursor: pointer;

  &::before {
    @include transition(var(--transition-default));
    display: inline-block;
  }

  &.rotate {
    &::before {
      @include transform(rotate(-180deg));
    }
  }
}

.default-dropdown {
  position: relative;

  &__selected {
    @include ellipsis-text;
    height: 44px;
    color: var(--primary-color);
    line-height: 42px;
    background-color: var(--neutral-200-color);
    border-radius: 4px;
    border: 1px solid var(--neutral-200-color);
    padding: 0 48px 0 16px;

    &.focused {
      border-color: var(--primary-color);
    }
    &.text-gray {
      color: var(--neutral-500-color);
    }
  }

  ul {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    width: auto;
    min-width: 100%;
    max-height: 208px;
    background: var(--neutral-color);
    @include box-shadow(var(--box-shadow-default));
    border-radius: 0 0 2px 2px;
    overflow-y: auto;
    z-index: 1;

    &.block {
      max-width: 100%;
    }

    &.open {
      display: block;
      padding: 8px 16px;
    }

    li {
      color: var(--primary-color);
      height: 40px;
      padding: 4px 0;

      label {
        display: inline-block;
        line-height: 32px;
      }
    }
  }

  .dropdown-radio-label {
    @include ellipsis-text;
    cursor: pointer;
    a {
      display: block;
      color: var(--primary-color);
      &:hover {
        color: var(--secondary-color);
      }
    }
  }

  .hidden-input:checked + .dropdown-radio-label {
    font-family: 'Barlow-Medium';
  }
  // Sorting dropdown
  &.sorting {
    display: inline-block;
    width: 184px;

    .default-dropdown__selected {
      @include ellipsis-text;
      padding: 0 40px 0 8px;
    }

    ul {
      left: auto;
      right: 0;
      max-height: unset;
      padding: 0 16px;

      .loader-wrapper {
        height: 40px;
        text-align: center;
        line-height: 32px;
        padding: 4px 0;
        overflow: hidden;

        .loader {
          width: 28px;
          height: 28px;
          margin: auto;
        }
      }
    }
  }

  &.inverse-color {
    text-align: center;
    .default-dropdown__selected {
      color: var(--neutral-color);
      background: var(--primary-color);
    }

    .icon-chevron-down {
      color: var(--neutral-color);
    }
  }
}

// User dropdown in Header
.user-dropdown {
  position: relative;
  width: 200px;
  height: 44px;
  border-radius: 2px;
  border: 1px solid var(--neutral-200-color);
  margin-left: 16px;

  .user-name {
    font-family: 'Barlow-Semibold';
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.4;
    word-break: break-word;
  }

  .user-mail {
    font-size: 0.875rem;
    color: var(--neutral-500-color);
    text-align: center;
  }

  > .user-name {
    @include ellipsis-text;
    line-height: 42px;
    padding-right: 32px;
    padding-left: 8px;
    cursor: pointer;
  }

  &-menu {
    position: absolute;
    top: 102%;
    right: 0;
    display: none;
    min-width: 100%;
    text-align: center;
    background: var(--neutral-color);
    @include box-shadow(var(--box-shadow-default));
    border-radius: 0 0 2px 2px;
    z-index: 1;

    &.open {
      display: block;
    }

    li {
      padding: 24px;
      &:first-child {
        border-bottom: 1px solid var(--neutral-200-color);
      }
    }

    .account-logo {
      width: 80px;
      height: 80px;
      margin: 0 auto 16px;
      -o-object-fit: contain;
      object-fit: contain;

      &.empty {
        background: var(--neutral-300-color);
      }
    }
  }

  &-link {
    @include transition(var(--transition-default));
    display: block;
    width: 100%;
    height: 44px;
    font-family: 'Barlow-Medium';
    font-size: 0.875rem;
    color: var(--primary-color);
    line-height: 42px;
    text-transform: uppercase;
    border-radius: 2px;
    border: 1px solid var(--neutral-300-color);
    margin-top: 24px;

    &:hover {
      color: var(--secondary-color);
      border-color: var(--secondary-color);
      background: var(--secondary-100-color);
    }
  }
}

// Notifications
.notifications-dropdown {
  position: absolute;
  top: 12px;
  right: 56px;
  height: 44px;
  margin-left: 16px;

  .icon-notification {
    position: relative;
    font-size: 1.2rem;
    line-height: 44px;

    &.new::after {
      content: '';
      position: absolute;
      top: 12px;
      right: 6px;
      width: 8px;
      height: 8px;
      background: var(--error-500-color);
      border-radius: 50%;
    }
  }

  .icon-close {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &-menu {
    display: none;

    &.open {
      position: fixed;
      z-index: 4;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      background: var(--neutral-color);
      ul {
        height: calc(100% - 63px);
        overflow-y: auto;

        @media screen and (min-width: 1025px) {
          height: calc(100% - 33px);
        }
      }
    }

    h1 {
      line-height: 62px;
      padding: 0 16px;
      border-bottom: 1px solid var(--neutral-300-color);
    }

    h3 {
      width: 100%;
      padding: 24px 16px 8px;
      border-bottom: 1px solid var(--neutral-300-color);
    }
  }

  @media screen and (min-width: 1025px) {
    position: relative;
    top: 0;
    right: auto;

    .icon-close {
      display: none;
      pointer-events: none;
    }

    h1 {
      line-height: 1.2;
      padding: 0 32px;
      border-bottom: none;
    }
    h3 {
      padding: 8px 32px;
    }

    &-menu {
      width: 552px;

      &.open {
        @include box-shadow(var(--box-shadow-default));
        position: absolute;
        top: 102%;
        right: 0;
        left: auto;
        bottom: auto;
        height: 320px;
        padding-top: 24px;
        border-radius: 2px;
      }
    }
  }

  .loader-wrapper {
    width: 100%;
    height: 104px;
    text-align: center;
    overflow: hidden;
  }
}

.notification-card {
  @include flex;
  @include flex-wrap;
  padding: 16px;
  border-bottom: 1px solid var(--neutral-300-color);

  &.unread {
    background: var(--neutral-100-color);
  }

  &__image {
    position: relative;
    width: 56px;
    height: 56px;
    overflow: hidden;

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    img,
    > div {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
    > div {
      background: var(--neutral-300-color);
    }
  }

  &__details,
  &__info {
    @include flex;

    a {
      color: var(--primary-color);
      &:hover {
        color: var(--secondary-color);
      }
    }
  }

  &__details {
    @include space-between;
    @include align-flex-end;
    width: 100%;
    padding-top: 16px;

    .gray-text {
      font-size: 0.875rem;
      white-space: nowrap;
    }
  }

  &__info {
    @include flex-column;
    @include space-around;
    width: calc(100% - 56px); // 56px - width of &__image
    padding: 0 16px;

    p {
      word-break: break-word;
    }
  }

  &__btns-wrapper {
    > button {
      margin: 16px 16px 0 0;
    }
  }

  &__collapsible-text {
    p {
      height: auto;

      &.small {
        cursor: pointer;
        &:hover {
          color: var(--secondary-color);
        }
      }
    }

    &.close {
      p {
        -webkit-line-clamp: 2;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        overflow-wrap: break-word;
        -webkit-box-orient: vertical;
        max-height: 3em;
        min-height: 18px;
        word-break: break-word;
      }
    }
  }

  @media screen and (min-width: 481px) {
    padding: 24px 32px;

    &__details {
      @include flex-column;
      @include align-flex-end;
      width: 96px;
      text-align: right;
      padding-top: 0;
    }

    &__info {
      width: calc(100% - 152px);
      // 96px - width of &__details, 56px - width of &__image
      padding: 0 16px;
    }

    &__btns-wrapper {
      > button {
        width: 88px;
      }
    }
  }
}

.gray-badge {
  display: inline-block;
  width: auto;
  font-family: 'Barlow-Semibold';
  font-size: 0.75rem;
  text-align: center;
  background: var(--neutral-300-color);
  border-radius: 2px;
  padding: 4px 8px;
}
