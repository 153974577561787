.short-lists {
  @include container-width(1174px);
  padding: 24px;
  margin: 0 auto;

  > h1 {
    width: 100%;
    line-height: 1.9;

    button {
      width: 100%;
      margin-top: 24px;
    }

    @media screen and (min-width: 481px) {
      position: relative;

      button {
        position: absolute;
        right: 0;
        width: auto;
        margin-top: 0;
      }
    }
  }

  p {
    word-break: break-word;
  }

  &-content {
    @include flex;
    @include flex-wrap;

    &.grid {
      position: relative;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: (minmax(0, 1fr));
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-row-gap: 0;
      padding-bottom: 32px;

      @media screen and (min-width: 769px) {
        -ms-grid-columns: minmax(0, 1fr) 1rem minmax(0, 1fr);
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 1rem;
        padding-bottom: 104px;
      }

      @media screen and (min-width: 1024px) {
        -ms-grid-columns: minmax(0, 1fr) 1rem minmax(0, 1fr) 1rem minmax(0, 1fr);
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
    .white-card {
      margin-bottom: 0;

      &:hover {
        @include box-shadow(var(--box-shadow-hover));

        h3 {
          color: var(--secondary-color);
        }
      }
    }
  }

  .short-list-card {
    &__logos {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: minmax(0, 1fr) 1rem minmax(0, 1fr);
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 0.5rem;
      grid-row-gap: 0.5rem;

      @media screen and (min-width: 481px) {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        padding: 16px;
      }

      &-wrapper {
        position: relative;
        overflow: hidden;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
          object-fit: contain;
        }
      }

      &-wrapper,
      .no-image {
        width: 100%;
        padding-bottom: 100%;
        border-radius: 2px;
      }
      .no-image {
        background: var(--neutral-300-color);
      }
    }

    h3,
    p {
      @include ellipsis-text;
      padding: 0 16px;
      text-align: center;
      padding-bottom: 8px;
    }
    h3 {
      color: var(--primary-color);
      padding-top: 8px;
    }
  }

  &__empty-state {
    @include flex;
    @include flex-column;
    @include justify-center;
    @include flex-align-center;
    width: 100%;
    min-height: 50vh;
    padding: 40px 0;

    img {
      width: 56px;
      margin-bottom: 8px;
    }

    .btn-secondary {
      &::before {
        font-size: 1.2rem;
        margin-right: 8px;
        vertical-align: -3px;
      }
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    max-width: 744px;

    h1 {
      position: relative;
      display: inline-block;
      width: auto;
      max-width: 100%;
      word-break: break-word;
      padding-right: 112px;
      @media screen and (min-width: 1001px) {
        max-width: calc(100% - 204px);
      }
    }

    .sorting {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__action-btns {
    position: absolute;
    top: -4px;
    right: 0;

    button {
      width: 40px;
      height: 40px;
      font-size: 1.2rem;
      color: var(--primary-color);
      line-height: 38px;
      margin-left: 16px;
    }
  }
}

.list-item-card {
  position: relative;
  display: block;
  width: 100%;
  color: var(--primary-color);
  background: var(--neutral-color);
  border-radius: 2px;
  border: 1px solid var(--neutral-300-color);
  margin-bottom: 16px;

  &__link {
    @include transition(var(--transition-default));
    display: block;
    width: 100%;
    font-family: 'Barlow-Medium';
    font-size: 0.875rem;
    color: var(--neutral-color);
    text-align: center;
    text-transform: uppercase;
    background: var(--primary-color);
    padding: 12px;
    cursor: pointer;

    &:hover {
      background: var(--secondary-color);
    }

    span {
      font-size: 0.8rem;
      line-height: 16px;
      margin-left: 8px;
    }
  }

  &__info-wrapper {
    @include flex;
    @include flex-wrap;

    img {
      width: 64px;
      height: 64px;
      -o-object-fit: contain;
      object-fit: contain;
    }

    &--img,
    .industry-leader-empty-image {
      display: inline-block;
      width: 64px;
      height: 64px;
    }

    &--img {
      @include flex-auto;
      overflow: hidden;
    }

    &--img-wrapper {
      @include flex;
      @include flex-align-center;
      width: 100%;
      border-bottom: 1px solid var(--neutral-300-color);
      padding: 16px;

      > h3 {
        line-height: 1.4;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        overflow-wrap: break-word;
        -webkit-box-orient: vertical;
        max-height: 3em;
        min-height: 18px;
        word-break: break-word;
        padding-left: 16px;
      }
    }

    @media screen and (min-width: 769px) {
      padding: 16px 16px 24px;

      &--img,
      .industry-leader-empty-image,
      img {
        width: 100px;
        height: 100px;
      }

      &--img-wrapper {
        display: inline-block;
        width: auto;
        border: none;
        padding: 0;

        > h3 {
          display: none;
        }
      }
    }
  }

  &__main-info {
    @include flex;
    @include flex-column;
    @include space-between;
    @include flex-auto;
    position: relative;
    width: 100%;
    padding: 16px 16px 8px;

    > h3,
    p {
      @include ellipsis-text;
    }

    > h3 {
      display: none;

      @media (hover: hover) and (pointer: fine) {
        &:hover + .name-tooltip {
          @include box-shadow(var(--box-shadow-default));
          width: auto;
          max-width: 100%;
          height: auto;
          padding: 8px;
          border: 1px solid var(--neutral-200-color);
        }
      }
    }

    p {
      line-height: 1.9;

      span {
        display: block;
        padding-right: 16px;

        &::before {
          vertical-align: -3px;
          font-size: 1.25rem;
          margin-right: 8px;
        }
      }

      &.light-text {
        color: var(--neutral-500-color);
      }
    }

    @media screen and (min-width: 769px) {
      width: calc(100% - 148px);
      padding: 8px 16px;

      > h3 {
        display: block;
      }

      p {
        line-height: 1.7;

        span {
          display: inline-block;
        }
      }
    }
  }

  &__rating {
    @include flex;
    @include space-between;
    width: 100%;
    padding: 0 16px 8px;

    > div {
      font-size: 1rem;

      &::before {
        vertical-align: -2px;
        font-size: 1.25rem;
      }
    }

    .icon-star {
      font-family: 'Barlow-Semibold';

      &::before {
        margin-right: 8px;
      }
    }
    button.icon-edit {
      font-size: 1.2rem;
      padding: 0;
    }

    @media screen and (min-width: 769px) {
      @include flex-column;
      @include align-flex-end;
      width: 48px;
      padding: 8px 0;

      .icon-star {
        position: relative;
        padding-right: 24px;

        &::before {
          position: absolute;
          right: 0;
          margin-right: 0;
        }
      }
    }
  }

  &__description {
    width: 100%;
    padding-top: 8px;

    p {
      padding: 8px 16px;
      background: var(--neutral-100-color);
      word-break: break-word;
    }

    @media screen and (min-width: 769px) {
      padding: 8px 0 0 116px;

      p {
        border-radius: 2px;
      }
    }
  }
}
