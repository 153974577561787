.name-tooltip {
  @include transition(var(--transition-default));
  position: absolute;
  left: 16px;
  bottom: 100%;
  width: 0;
  height: 0;
  color: var(--neutral-color);
  word-break: break-word;
  background: var(--neutral-500-color);
  border-radius: 4px;
  border-color: var(--neutral-200-color);
  overflow: hidden;
  pointer-events: none;
}

section.landing-section {
  > h1 {
    width: 100%;
    line-height: 1.4;
    text-align: center;
    padding: 48px 24px 24px;
    margin: 0 auto;

    @media screen and (min-width: 769px) {
      padding-top: 104px;
    }
  }
}
// Search block
.landing-page-block {
  @media screen and (min-width: 751px) {
    height: calc(100vh - 64px); // 64px - padding-top of .page-content
  }

  &__search-content-wrapper {
    position: relative;
    height: auto; // 80px - padding-top of &__logo-block
    background: url('../../assets/landing/mobile-map.svg') no-repeat center;
    background-size: cover;
    padding: 0 16px;

    @media screen and (min-width: 751px) {
      height: calc(100% - 80px); // 80px - padding-top of &__logo-block
    }

    @media screen and (min-width: 769px) {
      background: url('../../assets/landing/map.svg') no-repeat center;
      background-size: cover;
    }
  }

  &__search-content {
    @include container-width(1126px);
    @include flex;
    @include flex-column;
    @include flex-align-center;
    @include justify-center;
    height: 100%;
    padding: 40px 0 96px;

    > div {
      width: 100%;
      text-align: center;
    }

    h1,
    p {
      padding-bottom: 16px;
    }
    h1 {
      line-height: 1.4;
    }

    p {
      line-height: 1.6;
    }

    @media screen and (min-width: 601px) {
      > div {
        width: 552px;
      }

      h1 {
        padding-bottom: 24px;
      }

      p {
        padding-bottom: 32px;
      }
    }

    @media screen and (min-width: 769px) {
      background-image: url(../../assets/landing/search-bg-1.png),
        url(../../assets/landing/search-bg-2.png),
        url(../../assets/landing/search-bg-3.png),
        url(../../assets/landing/search-bg-4.png);
      background-position: 3% 93%, 95% 3%, 28% 2%, 90% 95%;
      background-repeat: no-repeat;
      background-size: 320px, 240px, 180px, 180px;
      padding: 0;
    }
  }

  &__logos-block {
    width: 100%;
    height: auto;
    background: var(--primary-color);
    padding: 0 24px;

    &-wrapper {
      @include flex;
      @include flex-wrap;
      @include justify-center;
      @include container-width(1126px);
    }

    img {
      @include flex-auto;
      width: 80px;
      height: 80px;
      margin: 0 8px;
      -o-object-fit: contain;
      object-fit: contain;
    }

    @media screen and (min-width: 769px) {
      height: 80px;
    }

    @media screen and (min-width: 901px) {
      img {
        margin: 0 16px;
      }
    }
  }
}

.search-form {
  @include box-shadow(var(--box-shadow-default));
  width: 100%;
  height: auto;
  position: relative;

  input {
    width: 100%;
    height: 56px;
    color: var(--primary-color);
    background-color: var(--neutral-color);
    border: 2px solid var(--neutral-300-color);
    border-radius: 4px;
    padding: 0 48px 0 16px;

    &:focus {
      border-color: var(--neutral-500-color);
      outline: none;
    }

    &::placeholder {
      color: var(--neutral-500-color);
    }
    &::-webkit-input-placeholder {
      color: var(--neutral-500-color);
    }
    &::-moz-placeholder {
      color: var(--neutral-500-color);
    }
    &:-ms-input-placeholder {
      color: var(--neutral-500-color);
    }

    @media screen and (min-width: 769px) {
      font-size: 0.875rem;
    }
  }

  button {
    position: absolute;
    right: 2px;
    bottom: 0;
    height: 56px;
  }
}

// Categories block
.categories {
  background: var(--neutral-color);

  &__grid {
    max-width: 1440px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: 1rem;
    // row-gap: 1rem;
    margin: 0 auto;

    @media screen and (min-width: 769px) {
      -ms-grid-columns: minmax(0, 1fr) 1rem minmax(0, 1fr);
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 1rem;
      // column-gap: 1rem;
    }
  }
}

.category-card {
  position: relative;
  width: 100%;
  color: var(--neutral-color);
  padding-bottom: 59.4%;

  &.cursor-pointer {
    cursor: pointer;
  }

  img,
  &__image-cover,
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  img {
    @include transition(var(--transition-default));
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  &__image-cover {
    background: var(--primary-color);
    opacity: 0.5;
  }
  &__link {
    @include flex;
    @include flex-column;
    @include justify-center;

    h1 {
      height: 50%;
      text-align: center;
      padding-top: 25%;
      span {
        display: inline-block;
        font-size: 0.6rem;
        padding-left: 8px;
        line-height: 24px;
        vertical-align: top;

        &::before {
          display: inline-block;
          line-height: 24px;
          vertical-align: middle;
        }
      }
    }

    p {
      @include flex;
      @include justify-center;
      @include align-flex-end;
      height: 50%;
      font-size: 0.75rem;
      text-align: center;
      padding-bottom: 16px;
    }
  }

  @media screen and (min-width: 481px) {
    &__link {
      h1 {
        font-size: 1.3rem;
      }
    }
  }

  @media screen and (min-width: 769px) {
    &__image-cover {
      opacity: 0.4;
    }
    img {
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
    }
    &:hover {
      img {
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
      }
    }
  }

  @media screen and (min-width: 951px) {
    &__link {
      h1 {
        font-size: 1.75rem;
        span {
          font-size: 0.8rem;
        }
      }
    }
  }
}

// Industry Leaders Block
.industry-leaders {
  padding: 0 16px;

  h1 {
    position: relative;

    .btn-minimal {
      position: absolute;
      right: 0;
      bottom: 20px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 0.6rem;

      &::before {
        @include transition(transform 0.2s ease-out);
      }

      &.rotate {
        &::before {
          @include transform(rotate(-180deg));
          display: inline-block;
        }
      }
    }
  }

  &__collapsible-block {
    @include transition(min-height 0.3s ease-out);
    @include container-width(1126px);
    min-height: 220px;
    height: 220px;
    border-bottom: 1px solid var(--neutral-400-color);
    overflow: hidden;

    &.with-link {
      min-height: 260px;
      height: 260px;
    }

    &.open {
      @include transition(min-height 0.3s ease-out);
      min-height: 880px;
      height: auto;
      overflow: unset;
    }
  }

  &__grid {
    position: relative;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: 1rem;
    padding-bottom: 32px;

    @media screen and (min-width: 769px) {
      -ms-grid-columns: minmax(0, 1fr) 1rem minmax(0, 1fr);
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 1rem;
      padding-bottom: 104px;
    }
  }

  @media screen and (min-width: 769px) {
    h1 {
      .btn-minimal {
        display: none;
        pointer-events: none;
      }
    }

    &__collapsible-block {
      min-height: auto;
      height: auto;
      overflow: unset;

      &.open,
      &.with-link {
        min-height: auto;
        height: auto;
        overflow: unset;
      }
    }
  }
}

.view-all-link {
  font-family: 'Barlow-Medium';
  font-size: 0.875rem;
  text-transform: uppercase;
  margin: 16px auto 0;
  cursor: pointer;

  span:not(.icon-chevron-double-right) {
    text-decoration: underline;
  }

  span::before {
    font-size: 0.06rem;
    margin-left: 10px;
    vertical-align: 2px;
  }

  @media screen and (min-width: 769px) {
    position: absolute;
    top: -44px;
    right: 8px;
    margin: 0;
  }
}

.industry-leader-card {
  @include box-shadow(var(--box-shadow-default));
  position: relative;
  width: 100%;
  background: var(--neutral-color);

  &.without-shadow {
    @include box-shadow(none);
    margin-bottom: 16px;

    @media screen and (min-width: 1001px) {
      border-radius: 2px;
      border: 1px solid var(--neutral-300-color);
    }
  }

  &__link {
    @include transition(var(--transition-default));
    display: block;
    width: 100%;
    font-family: 'Barlow-Medium';
    font-size: 0.875rem;
    color: var(--neutral-color);
    text-align: center;
    text-transform: uppercase;
    background: var(--primary-color);
    padding: 12px;
    cursor: pointer;

    &:hover {
      background: var(--secondary-color);
    }

    span {
      font-size: 0.8rem;
      line-height: 16px;
      margin-left: 8px;
    }
  }

  &__info-wrapper {
    @include flex;
    @include flex-wrap;
    color: var(--primary-color);

    &.desktop-lnk:hover {
      @include transition(var(--transition-default));
      @include box-shadow(var(--box-shadow-default));
    }

    img {
      width: 64px;
      height: 64px;
      -o-object-fit: contain;
      object-fit: contain;
    }

    &--img,
    .industry-leader-empty-image {
      @include flex-auto;
      display: inline-block;
      width: 64px;
      height: 64px;
      overflow: hidden;
    }

    &--img-wrapper {
      @include flex;
      @include flex-align-center;
      width: 100%;
      border-bottom: 1px solid var(--neutral-300-color);
      padding: 16px;

      > h3 {
        line-height: 1.4;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        overflow-wrap: break-word;
        -webkit-box-orient: vertical;
        max-height: 3em;
        min-height: 18px;
        word-break: break-word;
        padding-left: 16px;
      }
    }

    @media screen and (min-width: 769px) {
      padding: 32px;

      &--img,
      .industry-leader-empty-image,
      img {
        width: 100px;
        height: 100px;
      }

      &--img-wrapper {
        display: inline-block;
        width: auto;
        border: none;
        padding: 0;

        > h3 {
          display: none;
        }
      }
    }
  }

  &__main-info {
    @include flex;
    @include flex-column;
    @include space-between;
    @include flex-auto;
    position: relative;
    width: 100%;
    padding: 16px 16px 8px;

    > h3,
    p {
      @include ellipsis-text;
    }

    > h3 {
      display: none;

      @media (hover: hover) and (pointer: fine) {
        &:hover + .name-tooltip {
          @include box-shadow(var(--box-shadow-default));
          width: auto;
          max-width: 100%;
          height: auto;
          padding: 8px;
          border: 1px solid var(--neutral-200-color);
        }
      }
    }

    p {
      line-height: 1.9;

      &::before {
        vertical-align: middle;
        font-size: 1.25rem;
        margin-right: 8px;
      }

      &.light-text {
        color: var(--neutral-500-color);
      }
    }

    @media screen and (min-width: 769px) {
      width: calc(100% - 148px);
      padding: 8px 16px;

      > h3 {
        display: block;
      }

      p {
        line-height: 1.7;
      }
    }
  }

  &__rating {
    @include flex;
    @include space-between;
    width: 100%;
    padding: 0 16px 16px;

    > div {
      font-size: 1rem;

      &::before {
        vertical-align: -2px;
        font-size: 1.25rem;
      }
    }

    .icon-star {
      font-family: 'Barlow-Semibold';

      &::before {
        margin-right: 8px;
      }
    }

    .icon-bookmark,
    .icon-icon-bookmark {
      z-index: 1;
    }

    @media screen and (min-width: 769px) {
      @include flex-column;
      @include align-flex-end;
      width: 48px;
      padding: 8px 0;

      .icon-star {
        position: relative;
        padding-right: 24px;

        &::before {
          position: absolute;
          right: 0;
          margin-right: 0;
        }
      }
    }
  }
}

// Contact Us block
.contact-us {
  padding: 48px 16px;

  &__form-wrapper {
    @include container-width(1126px);
    padding: 0;
  }

  form {
    width: 100%;
    border-radius: 8px;
    background: var(--neutral-color);
    padding: 16px;

    textarea {
      min-height: 112px;
    }
  }

  &__inputs-grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: 1rem;
    padding: 1rem 0;

    @media screen and (min-width: 769px) {
      -ms-grid-columns: minmax(0, 1fr) 1rem minmax(0, 1fr);
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 1.5rem;
      padding: 1.5rem 0;
    }
  }

  .btn-primary {
    width: 100%;
  }

  .group {
    position: relative;
    &:first-child {
      margin-bottom: 1rem;
    }

    input {
      margin-bottom: 0;
    }

    .error {
      font-size: 0.875rem;
    }
  }

  @media screen and (min-width: 481px) {
    .btn-primary {
      width: auto;
    }
  }

  @media screen and (min-width: 769px) {
    form {
      padding: 32px;
      .group {
        &:first-child {
          margin-bottom: 1.5rem;
        }

        .error {
          position: absolute;
          left: 0;
          bottom: -16px;
        }
      }

      textarea {
        min-height: 100%;
      }
    }
  }
  @media screen and (min-width: 1025px) {
    &__form-wrapper {
      padding: 0 96px;
    }
  }
}
