.profile {
  background: var(--neutral-color);
  padding-bottom: 24px;

  @media screen and (min-width: 769px) {
    padding-bottom: 96px;
  }

  &__info-wrapper {
    width: 100%;
    background: var(--neutral-100-color);
    padding: 16px;

    ul {
      @include container-width(1126px);

      li {
        line-height: 1.2;
        padding: 8px 0;

        &::before {
          vertical-align: bottom;
          margin-right: 8px;
          font-size: 1.25rem;
        }
      }
      @media screen and (min-width: 1001px) {
        li {
          display: inline-block;
          padding: 0;

          &:not(:last-child) {
            padding-right: 24px;
          }
        }
      }
    }
  }

  &__main-info-wrapper {
    @include flex;
    @include flex-wrap;
    @include container-width(1440px);
    padding-bottom: 24px;

    @media screen and (min-width: 1001px) {
      padding-left: 16px;
      padding-bottom: 56px;
    }

    @media screen and (min-width: 1281px) {
      padding-left: 0;
    }
  }

  &__main-info {
    width: 100%;
    padding: 24px 16px;
    margin-left: auto;

    &--heading {
      @include flex;

      &-logo {
        width: 96px;
        height: 96px;
        -o-object-fit: contain;
        object-fit: contain;
        margin-right: 16px;
      }

      > div {
        @include flex;
        @include flex-column;
        @include space-between;
        flex-direction: column;
        justify-content: space-between;
      }

      h1 {
        position: relative;
        width: calc(100% - 96px);
        word-break: break-word;
        padding-right: 56px;
        margin-bottom: 16px;

        a {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      h3 {
        position: relative;
        line-height: 1.4;
        padding-top: 16px;
        word-break: break-word;

        &::before {
          margin-right: 8px;
        }

        .btn-view-all {
          margin-left: 8px;
          vertical-align: middle;
        }
      }
    }

    &--btn-wrapper {
      padding-top: 8px;

      > button,
      a {
        &:first-child {
          margin-right: 1rem;
        }
      }
    }

    &--list {
      h3 {
        padding: 24px 0 4px;
      }

      li {
        line-height: 1.4;
        padding: 4px 0;
      }
    }

    @media screen and (min-width: 1001px) {
      width: 58%;
      max-width: 678px;
      padding: 32px 32px 32px 0;
      margin-left: auto;

      &--heading {
        &-logo {
          width: 140px;
          height: 140px;
        }

        > div {
          padding-left: 24px;
        }
        h1 {
          width: 100%;
          font-size: 2.125rem;
          margin-bottom: 0;
        }
        h3 {
          padding-top: 0;
        }
      }

      &--btn-wrapper {
        height: 52px;

        > button,
        a {
          padding: 0 24px;
          &:first-child {
            margin-right: 1.5rem;
          }
        }
      }
    }
  }

  &__cover-image-wrapper {
    position: relative;
    width: 100%;
    > img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    .down-arrow-icon {
      display: none;
    }

    @media screen and (min-width: 1001px) {
      width: 42%;

      .down-arrow-icon {
        position: absolute;
        bottom: 16px;
        left: -32px;
        display: inline-block;
      }
    }
  }

  &__projects-wrapper {
    @include flex;
    @include container-width(1440px);

    @media screen and (min-width: 1001px) {
      padding-left: 16px;
      padding-bottom: 96px;
    }

    @media screen and (min-width: 1281px) {
      padding-left: 0;
    }
  }

  &__projects {
    @include flex;
    @include flex-wrap;
    width: 100%;

    > div {
      width: 100%;
    }

    &-description {
      word-break: break-word;
    }

    @media screen and (min-width: 769px) {
      > div {
        width: 50%;
      }
    }

    @media screen and (min-width: 1001px) {
      display: block;
      width: 34%;
      max-width: 360px;
      margin-left: auto;

      > div {
        width: 100%;
      }
    }
  }

  &__projects-map-wrapper {
    position: relative;
    display: block;
    width: 66%;
    min-height: 400px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0 0 16px 32px;
    z-index: 1;
    overflow: hidden;

    h3 {
      position: absolute;
      top: 8px;
      right: 80px;
      z-index: 1000;
    }

    @media screen and (min-width: 1281px) {
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
    }
  }

  &__image-block {
    @include container-width(1440px);
  }

  &__action-button-wrapper {
    width: 100%;
    text-align: center;
    padding: 0 16px;

    > button,
    a {
      width: 100%;
      margin-top: 16px;
    }

    @media screen and (min-width: 481px) {
      padding: 8px 16px 0;

      > button,
      a {
        width: auto;
        padding: 0 24px;
        margin: 0 12px;
      }
    }
  }
}

.grey-box {
  background: var(--neutral-100-color);
  padding: 16px;
  margin-bottom: 16px;

  h3 {
    padding-top: 0;
  }

  p {
    font-size: 1rem;
    line-height: 1.7;
    &:first-child {
      margin-bottom: 24px;
    }
  }
}

.wide-image-wrapper {
  position: relative;
  width: 100%;
  background: var(--neutral-200-color);

  .action-btns-wrapper {
    position: static;
    opacity: 1;
    pointer-events: auto;
    background: var(--neutral-color);
  }

  @media screen and (min-width: 769px) {
    .action-btns-wrapper {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      background: transparent;
    }
    &:hover {
      .action-btns-wrapper {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.image-info {
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.45;
  background: var(--neutral-200-color);
  padding: 8px 16px;
}

.double-images {
  &-wrapper {
    padding: 24px 0;
  }

  &__grid {
    @include flex;
    @include flex-wrap;
    @include justify-center;
    max-width: 1152px;
    margin: 0 auto;
    > h3 {
      width: 100%;
      padding: 4px 12px;

      label {
        padding-top: 8px;
      }
    }
    > div {
      width: 100%;
      padding: 8px 0;
    }
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
    background: var(--neutral-100-color);
    padding-bottom: 126%;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      .action-btns-wrapper {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__wrapper {
    @include flex;
    @include flex-wrap;
    @include container-width(1440px);
    padding: 24px 0;
  }

  &__small,
  &__large {
    width: 100%;
  }

  &__large {
    padding-bottom: 24px;
  }

  &__small {
    @include flex;
    @include flex-column;
    @include justify-center;
    margin-bottom: 24px;
  }

  @media screen and (min-width: 601px) {
    &-wrapper {
      padding: 48px 0;
    }

    &__grid {
      padding: 0 8px;

      > div {
        width: 50%;
        padding: 8px;
      }
    }

    &__small,
    &__large {
      width: 50%;
    }
    &__small {
      padding: 0 8px 0 16px;
      margin-bottom: 0;
    }
    &__large {
      padding: 0 16px 0 8px;
    }
  }

  @media screen and (min-width: 769px) {
    &__wrapper {
      padding: 48px 0;
    }
    &__small {
      width: 33%;
      padding: 0;
      margin-left: auto;
    }
    &__large {
      width: 42%;
      padding: 0;
      margin-left: 48px;
    }
  }

  @media screen and (min-width: 1201px) {
    &__grid {
      padding: 0;

      > div {
        padding: 12px;
      }
    }
  }
}

.partner-info {
  color: var(--neutral-color);
  background: var(--primary-color);
  padding: 8px 16px;

  h3 {
    line-height: 1.7;
  }

  > div {
    font-size: 1rem;
    line-height: 1.7;

    &::before {
      font-size: 1.2rem;
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  @media screen and (min-width: 601px) {
    color: var(--primary-color);
    background: var(--neutral-200-color);
  }
}

.profile-partners {
  background: var(--neutral-100-color);

  &__wrapper {
    @include container-width(1150px);
    padding: 48px 0 0;

    h3 {
      padding-left: 16px;

      span {
        margin-left: 8px;
      }
    }
  }
}
// Partner Card
.partner-card {
  display: inline-block;
  width: 100%;
  padding: 8px 16px;

  &__content-wrapper {
    @include transition(var(--transition-default));
    position: relative;
    width: 100%;
    padding-bottom: 132%;
    background: var(--neutral-color);
    @include box-shadow(var(--box-shadow-default));
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 44px;
    padding-top: 48px;
    text-align: center;

    .no-image,
    img {
      width: 220px;
      height: 220px;
    }

    img {
      -o-object-fit: contain;
      object-fit: contain;
    }

    .no-image {
      display: inline-block;
      background: var(--neutral-200-color);
    }
  }

  &__name-wrapper {
    position: relative;
    width: 100%;
  }

  &__name {
    @include ellipsis-text;
    font-family: 'Barlow-Semibold';
    text-transform: uppercase;
    padding: 48px 16px 0;

    @media (hover: hover) and (pointer: fine) {
      &:hover + .name-tooltip {
        @include box-shadow(var(--box-shadow-default));
        @include transform(translateX(-50%));
        left: 50%;
        bottom: 50%;
        width: auto;
        max-width: calc(100% - 32px);
        height: auto;
        white-space: initial;
        padding: 8px;
        border: 1px solid var(--neutral-200-color);
      }
    }
  }

  &__link {
    @include transition(var(--transition-default));
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 44px;
    font-family: 'Barlow-Medium';
    color: var(--neutral-color);
    font-size: 0.875rem;
    text-align: center;
    line-height: 44px;
    text-transform: uppercase;
    background: var(--primary-color);
    cursor: pointer;

    &:hover {
      background: var(--secondary-color);
    }
  }

  @media screen and (min-width: 481px) {
    width: 50%;
    padding: 12px;

    &__content {
      @include flex;
      @include flex-column;
      @include justify-center;
      @include flex-align-center;
      padding-top: 0;

      .no-image,
      img {
        width: 160px;
        height: 160px;
      }
    }
    &__name {
      padding-top: 24px;
    }
  }

  @media screen and (min-width: 769px) {
    width: 33.333333%;
  }

  @media screen and (min-width: 901px) {
    width: 25%;
  }
}

// Carousel
.carousel {
  position: relative;

  .content {
    @include transition(var(--transition-default));
    @include flex;
    width: 100%;
    position: relative;
    left: 0;
  }

  .wrapper {
    width: 100%;
    overflow: hidden;
  }

  &-content {
    width: 100%;
  }

  &-wrapper {
    width: 100%;
  }

  @media screen and (min-width: 769px) {
    &-content {
      white-space: nowrap;
      height: 440px;
      overflow-x: scroll;
    }

    &-wrapper {
      height: 400px;
      overflow: hidden;
    }
  }

  @media screen and (min-width: 901px) {
    &-wrapper {
      height: 360px;
    }
  }

  @media screen and (min-width: 1025px) {
    &-wrapper {
      height: 400px;
    }
  }

  &-nav {
    &__button {
      position: absolute;
      top: -40px;
      left: auto;
      right: 16px;
      display: block;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      border: 1px solid var(--neutral-300-color);
      background: transparent;
      padding: 0;
      outline: none;

      overflow: hidden;

      &[title='Prev'] {
        right: 72px;

        &::before {
          transform: rotate(180deg);
        }
      }

      &::before {
        display: inline-block;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 42px;
      }

      &:hover {
        color: var(--secondary-color);
        border-color: var(--secondary-color);
        background: var(--secondary-100-color);
      }

      &:disabled {
        color: var(--neutral-400-color);
        border-color: var(--neutral-400-color);
        background: transparent;
      }

      &:disabled {
        cursor: not-allowed;
      }

      @media screen and (min-width: 1281px) {
        top: 50%;
        right: -56px;
        transform: translateY(-50%);

        &[title='Prev'] {
          left: -56px;
        }
      }

      &.position-top {
        top: -40px;
        right: 16px;

        &[title='Prev'] {
          right: 72px;
          left: auto;
        }
      }
    }
  }
}
// Review
.reviews-wrapper {
  @include container-width(1150px);
  padding: 24px 0;

  > h3 {
    padding-left: 16px;
    padding-bottom: 8px;

    span {
      padding-left: 8px;
    }

    .icon-star {
      vertical-align: middle;
    }

    button {
      margin-left: 16px;
    }
  }

  @media screen and (min-width: 769px) {
    padding-top: 104px;

    > h3 {
      padding-left: 12px;

      .icon-star {
        font-size: 1.1rem;
        vertical-align: unset;
      }
    }
  }

  &__empty-state {
    width: 100%;
    padding: 0 16px;

    > div {
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 40px 16px;
    }

    @media screen and (min-width: 769px) {
      padding: 0 12px;
    }
  }
}

// Review card
.review-card {
  flex: 0 0 auto;
  display: inline-block;
  width: 100%;
  padding: 8px 16px;

  p {
    font-size: 0.875rem;
    white-space: normal;
    word-break: break-word;
    padding-top: 8px;
  }

  &-wrapper {
    background: var(--neutral-color);
    border-radius: 4px;
    border: 1px solid var(--neutral-200-color);
    padding: 16px;
  }

  &-info {
    @include flex;
    width: calc(100% - 128px); // 128px - width of .review-card-rating

    img {
      width: 56px;
      height: 56px;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }

  &__title {
    @include flex;
    @include flex-column;
    @include display-flex-end;
    width: calc(100% - 56px); // 56px - width of image
    padding-left: 16px;

    h3 {
      @include ellipsis-text;
    }
  }

  &__gray-uppercase {
    font-family: 'Barlow-Medium';
    font-size: 0.875rem;
    color: var(--neutral-500-color);
    line-height: 1.7;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &-heading.with-button {
    padding-bottom: 32px;
  }

  &-rating {
    @include flex;
    @include space-between;
    position: relative;
    padding: 8px 0;

    &__stars {
      font-size: 1.1rem;
      h3 {
        display: inline;
        font-size: 1.25rem;
        padding-right: 8px;
      }
    }

    .btn-minimal {
      position: absolute;
      bottom: -32px;
      left: 35%;
      font-family: 'Barlow-Medium';
      font-size: 0.875rem;
      line-height: 1.7;
      text-transform: uppercase;
      text-decoration: underline;
      padding: 0;
    }
  }

  @media screen and (min-width: 481px) {
    &-heading {
      @include flex;
      @include space-between;
      @include align-flex-end;

      &.with-button {
        padding-bottom: 0;
      }
    }

    &-rating {
      display: block;
      text-align: right;
      padding: 0;

      .btn-minimal {
        left: -88px;
        bottom: 0;
      }
    }
  }

  @media screen and (min-width: 769px) {
    width: 50%;
    padding: 12px;

    &-heading.with-button {
      padding-bottom: 32px;
    }

    &-rating {
      .btn-minimal {
        bottom: -32px;
      }
    }
  }

  @media screen and (min-width: 901px) {
    &-heading.with-button {
      padding-bottom: 0;
    }

    &-rating {
      .btn-minimal {
        bottom: 0;
      }
    }
  }
}

.tba-block {
  padding: 4px 8px;
  background: red;
}
