.full-container {
  @include flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 64px);
  // 64px - padding top of .page-content

  @media screen and (min-width: 769px) {
    height: calc(100vh - 264px);
    // 64px - padding top of .page-content
  }
}

.broken-link {
  text-align: center;

  img {
    width: 56px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  p {
    font-family: 'Barlow-Semibold';
    padding-top: 24px;
  }
}

.error-container {
  @extend .full-container;

  @media screen and (min-width: 769px) {
    height: calc(100vh - 64px);
    // 64px - padding top of .page-content
    background-image: url(../../assets/icons/connection-top.svg),
      url(../../assets/icons/connection-bottom.svg);
    background-position: left 2%, bottom right;
    background-repeat: no-repeat;
    background-size: 52%;
  }
}
.error-background-text {
  position: relative;
  width: 90%;
  font-family: 'Barlow-Black';
  font-size: 10rem;
  color: var(--neutral-color);
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 20px 20px rgba(0, 0, 0, 0.1);
  margin: 0 auto;

  @media screen and (min-width: 481px) {
    font-size: 13rem;
  }

  @media screen and (min-width: 769px) {
    width: 700px;
    font-size: 20rem;
  }
}
.error-content {
  @include flex;
  @include flex-column;
  @include flex-align-center;
  @include justify-center;

  width: 100%;
  color: var(--primary-color);

  &__heading {
    font-family: 'Barlow-Semibold';
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  p {
    max-width: 240px;
    font-family: 'Barlow';
    margin-bottom: 1.5rem;
  }

  a {
    width: 100%;
  }

  @media screen and (min-width: 481px) {
    a {
      width: auto;
    }
  }

  @media screen and (min-width: 769px) {
    position: absolute;
    top: 0;
    bottom: 0;

    p {
      max-width: 100%;
    }
  }
}
