input,
textarea {
  width: 100%;
  background-color: var(--neutral-200-color);
  border-radius: 4px;
  border: 1px solid var(--neutral-200-color);

  &::placeholder {
    color: var(--neutral-500-color);
  }
  &::-webkit-input-placeholder {
    color: var(--neutral-500-color);
  }
  &::-moz-placeholder {
    color: var(--neutral-500-color);
  }
  &:-ms-input-placeholder {
    color: var(--neutral-500-color);
  }

  &:focus {
    border-color: var(--primary-color);
    outline: none;
  }

  &.on-error {
    border-color: var(--error-500-color);
  }
}

input {
  height: 44px;
  padding: 0 16px;

  @media screen and (min-width: 769px) {
    padding: 0 44px;
  }
}

textarea {
  padding: 8px 16px;
}

.hidden-input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.input-group {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.mw-646 {
    max-width: 646px;
  }

  .right-position {
    width: 100%;
    text-align: center;

    @media screen and (min-width: 481px) {
      position: absolute;
      right: 0;
      width: auto;
    }
  }
}

.textarea-group {
  padding: 8px 0;

  textarea {
    min-height: 132px;
  }
}

.simple-label {
  font-size: 1rem;
  color: var(--neutral-500-color);
  line-height: 1.7;

  &:not(.with-icon) {
    input {
      padding: 0 16px;
    }
  }
  &.with-icon {
    input {
      padding: 0 44px;
    }
    .icon-search,
    .top-position,
    .middle-position,
    .icon-chevron-down,
    .toggle-visibility {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 44px;
      height: 44px;
      text-align: center;
      line-height: 44px;
    }
    .top-position {
      top: 0;
      bottom: auto;
    }
    .middle-position {
      top: 26px;
      bottom: auto;
    }
    .icon-chevron-down,
    .toggle-visibility {
      @extend .btn-minimal;
      left: auto;
      right: 0;
      font-size: 0.5rem;

      &::before {
        @include transition(var(--transition-default));
        display: inline-block;
      }

      &.rotate {
        &::before {
          @include transform(rotate(-180deg));
        }
      }
    }

    .toggle-visibility,
    .top-position,
    .middle-position {
      &::before {
        font-size: 1.3rem;
        line-height: 44px;
      }
    }
  }
}

.simple-fieldset {
  max-width: 646px;
  border: none;
  padding: 0 0 24px 0;
  margin: 0;

  legend {
    font-size: 1rem;
    color: var(--neutral-500-color);
    line-height: 1.7;
  }
  label {
    display: inline-block;
    width: 100%;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    overflow-wrap: break-word;
    -webkit-box-orient: vertical;
    max-height: 3em;
    min-height: 18px;
    word-break: break-word;
  }

  @media screen and (min-width: 481px) {
    label {
      width: 45%;
    }
  }
}

.simple-checkbox-label {
  font-size: 0.875rem;
  padding: 8px 0;

  &::before {
    @include transition(var(--transition-default));
    display: inline-block;
    font-family: 'icon-font' !important;
    content: '';
    width: 18px;
    height: 18px;
    font-size: 0.4rem;
    font-weight: 700;
    color: var(--neutral-color);
    text-align: center;
    line-height: 18px;
    background: var(--neutral-200-color);
    border-radius: 2px;
    margin-right: 12px;
    vertical-align: middle;
  }

  &.positioned {
    position: relative;
    display: inline-block;
    max-width: 480px;
    line-height: 1.7;
    padding-left: 32px;

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  &.half-width {
    @media screen and (min-width: 769px) {
      width: 50%;
    }
  }

  &:hover {
    &::before {
      background: var(--secondary-color);
    }
  }

  a.uppercase,
  strong {
    font-family: 'Barlow-Medium';
    color: inherit;
    font-weight: 500;
    word-break: break-word;
  }

  a.uppercase {
    text-decoration: underline;
    &:hover {
      color: var(--secondary-color);
    }
  }

  .count {
    padding-left: 4px;
  }
}

.simple-checkbox:checked + .simple-checkbox-label::before {
  content: '\e916';
  background: var(--primary-color);
}

// Overwrite react-phone-input-2/lib/style.css
.react-tel-input {
  .form-control {
    width: 100% !important;
    height: 44px !important;
    font-family: 'Barlow' !important;
    font-size: 1rem !important;
    background: var(--neutral-200-color) !important;
    border-radius: 4px !important;
    border-color: var(--neutral-200-color) !important;
    padding-left: 60px !important;
    &:focus {
      border-color: var(--primary-color) !important;
    }
  }

  .flag-dropdown {
    background-color: transparent !important;
    border: 0 !important;
    border-right: 1px solid var(--neutral-300-color) !important;
  }

  .selected-flag {
    width: 52px !important;
    padding-left: 16px !important;
    &:hover,
    &:focus,
    &.open {
      background-color: transparent !important;
    }
  }
}

.events-none {
  pointer-events: none;
}

.payment-label {
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 2px solid var(--neutral-300-color);
  padding: 16px 16px 16px 56px;
  margin-bottom: 16px;

  span {
    font-family: 'Barlow-Semibold';
  }

  &::before,
  &::after {
    @include transition(var(--transition-default));
    @include transform(translateY(-50%));
    position: absolute;
    top: 50%;
    display: block;
    border-radius: 50%;
    border: 2px solid var(--neutral-300-color);
    background: var(--neutral-300-color);
  }

  &::before {
    content: '';
    left: 16px;
    width: 16px;
    height: 16px;
  }

  &::after {
    left: 20px;
    width: 8px;
    height: 8px;
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
  }

  &:hover {
    border-color: var(--secondary-color);

    &::before,
    &::after {
      border-color: var(--secondary-color);
      background: var(--secondary-color);
    }
  }

  @media screen and (min-width: 481px) {
    width: 48%;
    margin-bottom: 8px;
  }
}

.hidden-input:checked + .payment-label {
  border-color: var(--primary-color);

  &::before {
    background: transparent;
    border-color: var(--primary-color);
  }

  &::after {
    content: '';
  }

  &:hover {
    &::before,
    &::after {
      border-color: var(--primary-color);
    }

    &::after {
      background: var(--primary-color);
    }
  }
}

.simple-radio-label {
  position: relative;
  font-size: 0.875rem;
  padding: 8px 0 8px 32px;

  &::before,
  &::after {
    @include transition(var(--transition-default));
    @include transform(translateY(-50%));
    position: absolute;
    top: 50%;
    display: block;
    border-radius: 50%;
    border: 2px solid var(--neutral-300-color);
    background: var(--neutral-300-color);
  }

  &::before {
    content: '';
    left: 0;
    width: 16px;
    height: 16px;
  }

  &::after {
    left: 4px;
    width: 8px;
    height: 8px;
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
  }
}

.hidden-input:checked + .simple-radio-label {
  &::before {
    background: transparent;
    border-color: var(--primary-color);
  }

  &::after {
    content: '';
  }
}
