:root {
  --primary-color: #1e1f23;
  --primary-S25-color: rgba(30, 31, 35, 0.25);

  --secondary-color: #3e9146;
  --secondary-100-color: #e4f9dc;
  --secondary-600-color: #2d7c3c;
  --secondary-S15-color: rgba(62, 145, 70, 0.15);

  --neutral-color: #ffffff;
  --neutral-100-color: #f8f9fa;
  --neutral-200-color: #f2f3f5;
  --neutral-300-color: #e5e9ed;
  --neutral-400-color: #c8cfd9;
  --neutral-500-color: #80909b;
  --neutral-S05-color: rgba(255, 255, 255, 0.5);
  --neutral-S08-color: rgba(255, 255, 255, 0.8);

  --color-footer-submenu: #292a2e;

  --error-100-color: #fbe5cf;
  --error-500-color: #b53717;

  --box-shadow-default: 0px 12px 32px rgba(68, 74, 78, 0.1);
  --box-shadow-hover: 0px 12px 32px rgba(68, 74, 78, 0.2);

  --transition-default: all 0.2s ease-out;
}
