button {
  @include transition(var(--transition-default));

  a {
    color: inherit;
  }
}

.lnk-as-btn-primary,
.lnk-btn-secondary {
  @include transition(var(--transition-default));
  display: inline-block;
  line-height: 44px;
  text-decoration: none;
  text-align: center;
}

.btn-primary,
.lnk-as-btn-primary,
.lnk-btn-secondary,
.btn-secondary {
  @include box-shadow(none);
  width: auto;
  height: 44px;
  font-family: 'Barlow-Medium';
  font-size: 0.875rem;
  text-transform: uppercase;
  padding: 0 16px;
  border-radius: 2px;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
}

.btn-primary,
.lnk-as-btn-primary {
  color: var(--neutral-color);
  background: var(--primary-color);
  border: none;

  &:hover {
    background: var(--secondary-color);
  }
  &:active,
  &:focus {
    background: var(--secondary-600-color);
  }

  &:disabled {
    background: var(--neutral-400-color);
  }

  &::before {
    margin-right: 8px;
  }
}

.btn-secondary,
.lnk-btn-secondary {
  color: var(--primary-color);
  background: transparent;
  border: 1px solid var(--neutral-300-color);

  &:hover {
    color: var(--secondary-color);
    border-color: var(--secondary-color);
    background: var(--secondary-100-color);
  }
  &:active,
  &:focus {
    color: var(--secondary-600-color);
    border-color: var(--secondary-600-color);
    background: var(--secondary-100-color);
  }

  &:disabled {
    color: var(--neutral-400-color);
    border-color: var(--neutral-400-color);
    background: transparent;
  }
}

.btn-rounded {
  width: 48px;
  height: 48px;
  font-size: 1.8rem;
  color: var(--neutral-color);
  line-height: 46px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid var(--neutral-500-color);
  outline: none;

  &:hover {
    color: var(--secondary-color);
    background-color: var(--secondary-S15-color);
    border-color: var(--secondary-color);
  }
}

.lnk-rounded {
  width: 44px;
  height: 44px;
  font-size: 1.2rem;
  color: var(--primary-color);
  line-height: 42px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--neutral-300-color);

  &:hover {
    color: var(--secondary-color);
    border-color: var(--secondary-color);
  }
}

.btn-minimal {
  color: var(--primary-color);
  font-size: 1rem;
  background: transparent;
  border: none;
  overflow: hidden;

  &:hover {
    color: var(--secondary-color);
  }

  &:hover,
  &:focus {
    outline: none;
  }
}

.btn-view-all {
  @extend .btn-minimal;
  font-family: 'Barlow-Medium';
  font-size: 0.875rem;
  text-transform: uppercase;
  text-decoration: underline;
  line-height: 20px;
  &:disabled {
    color: var(--neutral-500-color);
    cursor: not-allowed;
  }
}

.btn-action {
  @extend .btn-minimal;
  background: var(--neutral-S05-color);
  line-height: 1.8;
  padding: 0 8px;
  border-radius: 2px;

  &::before {
    font-size: 1.1rem;
    margin-right: 8px;
    vertical-align: text-bottom;
  }

  span {
    font-family: 'Barlow-Medium';
    font-size: 0.875rem;
    text-transform: uppercase;
    text-decoration: underline;
    pointer-events: none;
  }
}

.btn-action-icon {
  @extend .btn-minimal;
  font-size: 1.2rem;
  color: var(--neutral-500-color);
}

button.icon-search {
  @extend .btn-minimal;
  width: 48px;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.btn-back-to-profile {
  @extend .btn-minimal;

  &::before {
    display: inline-block;
    font-size: 0.785rem;
    margin-right: 8px;
    transform: rotate(180deg);
  }

  &:hover {
    color: var(--secondary-color);
  }
}

// Mobile menu button
.btn-menu {
  @include box-shadow(none);
  display: block;
  position: relative;
  top: 12px;
  right: -16px;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  outline: none;

  span {
    @include transition(background 0s 0.3s);
    display: block;
    position: absolute;
    top: 18px;
    left: 8px;
    right: 8px;
    height: 3px;
    background: var(--primary-color);

    &:before,
    &::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: var(--primary-color);
      content: '';
      transition-duration: 0.3s, 0.3s;
      transition-delay: 0.3s, 0s;
    }
    &::before {
      top: -8px;
      transition-property: top, transform;
    }

    &::after {
      bottom: -8px;
      transition-property: bottom, transform;
    }
  }
  &.menu-open {
    span {
      background: none;

      &::before,
      &::after {
        transition-delay: 0s, 0.3s;
      }

      &::before {
        @include transform(rotate(45deg));
        top: 0;
      }

      &::after {
        @include transform(rotate(-45deg));
        bottom: 0;
      }
    }
  }
  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.btn-logout {
  @extend .btn-minimal;
  width: auto;
  font-family: 'Barlow-Medium';
  font-size: 0.875rem;
  text-transform: uppercase;
  text-decoration: underline;
}
