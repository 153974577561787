.search-page {
  @include flex;
  @include flex-wrap;
  @include container-width(1174px);
  padding: 24px;
  margin: 0 auto;

  > h1 {
    width: 100%;
  }
}

.search-filters-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  background: transparent;
  z-index: 4;

  @include transform(translateX(-100%));
  @include transition(var(--transition-default));

  &.open {
    @include transform(translateX(0));
    background: var(--primary-S25-color);
  }

  @media screen and (min-width: 1001px) {
    @include transform(translateX(0));
    position: static;
    width: 384px;
    background: transparent;
    z-index: unset;
  }
}

.search-filters-panel {
  width: 100%;
  height: calc(100% - 64px); // 64px - height of .search-filters-heading
  padding: 16px;
  background: var(--neutral-color);
  overflow: auto;

  @media screen and (min-width: 481px) {
    width: 384px;
  }

  @media screen and (min-width: 1001px) {
    padding: 0 24px 0 0;
    height: auto;
  }
}

.search-content {
  width: 100%;

  @media screen and (min-width: 1001px) {
    width: calc(100% - 384px); // 384px - width of .search-filters-panel
  }

  &__dropdowns-wrapper {
    @include flex;
    @include space-between;
    padding: 16px 0;

    > * {
      width: 47%;

      > button {
        width: 100%;
      }
    }

    @media screen and (min-width: 481px) {
      -webkit-box-pack: unset;
      -ms-flex-pack: unset;
      justify-content: unset;

      > button {
        margin-left: 16px;
      }

      > * {
        width: auto;
      }

      > div:first-child {
        z-index: 2;
      }
    }

    @media screen and (min-width: 769px) {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0;
    }

    @media screen and (min-width: 1001px) {
      @include flex-column;

      > div:first-child {
        text-align: right;
        margin-bottom: 16px;
      }
    }
  }

  &__inputs-wrapper {
    position: relative;
    z-index: 2;
  }
}

.search-filters-block {
  border-radius: 2px;
  border: 1px solid var(--neutral-200-color);
  padding: 0 8px;
  margin: 16px 0;

  &__heading {
    @include flex;
    @include space-between;
    padding: 16px 0 8px;
  }

  li {
    &:not(:last-child) {
      border-bottom: 1px solid var(--neutral-300-color);
    }

    label {
      display: inline-block;
      width: 100%;
      line-height: 1.9;
    }
  }
}

.search-input-wrapper {
  width: 100%;
  max-width: 100%;
  padding-top: 24px;

  @media screen and (min-width: 769px) {
    max-width: 320px;
  }
  @media screen and (min-width: 1201px) {
    max-width: 464px;
  }
}

.search-filters-heading {
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 1.7;
  background: var(--neutral-color);
  padding: 16px;
  border-bottom: 1px solid var(--neutral-300-color);

  > .icon-close {
    position: absolute;
    top: 12px;
    right: 8px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  @media screen and (min-width: 481px) {
    width: 384px;
  }
}

.industry-leaders {
  &-content {
    @include flex;
    @include flex-wrap;
    width: 100%;
  }
  &-category {
    width: 100%;
    padding: 32px 0 16px;
    border-bottom: 1px solid var(--neutral-300-color);

    > h3 {
      position: relative;
      width: 100%;

      button.icon-chevron-down {
        position: absolute;
        right: 0;
        bottom: -8px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 0.6rem;

        &::before {
          @include transition(transform 0.2s ease-out);
        }

        &.rotate {
          &::before {
            @include transform(rotate(-180deg));
            display: inline-block;
            bottom: -4px;
          }
        }
      }
    }

    &__collapsible-block {
      @include transition(min-height 0.3s ease-out);
      width: 100%;
      min-height: 0;
      height: 0;
      overflow: hidden;

      &.open {
        @include transition(min-height 0.3s ease-out);
        min-height: 260px;
        height: auto;
        overflow: unset;
      }
    }

    @media screen and (min-width: 1001px) {
      @include flex;

      > h3 {
        width: 384px;

        button.icon-chevron-down {
          display: none;
        }
      }

      &__collapsible-block {
        width: calc(100% - 384px);
        min-height: auto;
        height: auto;
        overflow: unset;

        &.open {
          min-height: auto;
          height: auto;
          overflow: unset;
        }
      }
    }
  }
}

.input-range-wrapper {
  padding: 48px 16px 32px;
  border-top: 1px solid var(--neutral-300-color);
  margin: 8px 0 24px;

  // Overwrite react-input-range/lib/css/index.css

  .input-range__slider {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  .input-range__label--min,
  .input-range__label--max {
    color: var(--primary-color);
    bottom: -2rem;
  }

  .input-range__label--max {
    .input-range__label-container {
      left: 20%;

      &::after {
        content: '+ years';
      }
    }
  }

  .input-range__label--min {
    .input-range__label-container {
      left: -8px;

      &::after {
        content: ' years';
      }
    }
  }

  .input-range__track--active {
    background: var(--primary-color);
  }

  .input-range__label--value {
    top: -2.5rem;
  }

  .input-range__label-container {
    font-size: 1rem;
  }
}
