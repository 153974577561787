.modal {
  @include flex;
  @include justify-center;
  @include flex-align-center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary-S25-color);
  z-index: 4;

  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 768px) {
      width: auto;
      height: auto;
    }
  }

  &-body {
    background-color: var(--neutral-color);
    box-shadow: var(--box-shadow-default);
    width: 100%;
    height: 100%;

    &.wide {
      width: 100%;
    }

    @media screen and (min-width: 768px) {
      width: fit-content;
      height: auto;

      &.wide {
        @include flex;
        @include space-between;
        @include flex-column;
        width: 800px;
        height: 90vh;
      }
    }

    button.download {
      width: 106px;
    }
  }

  &-heading {
    position: relative;
    padding: 24px 16px;
    border-bottom: 1px solid var(--neutral-300-color);

    .icon-close {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 40px;
      height: 40px;

      &:hover {
        color: var(--secondary-color);
      }
    }

    p {
      max-width: 330px;
      padding-top: 8px;
      line-height: 1.4;

      &.wide {
        max-width: 480px;
        padding-bottom: 16px;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 24px;
    }
  }

  .action-buttons-wrapper {
    @include flex;
    @include display-flex-end;
    position: relative;
    padding: 32px 16px;

    &.mobile-bottom-position {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      @media screen and (min-width: 768px) {
        position: static;
      }
    }

    > button {
      width: 100%;
    }

    .error {
      position: absolute;
      left: 32px;
      top: 8px;
    }

    @media screen and (min-width: 481px) {
      > button {
        width: 152px;
      }
    }
    @media screen and (min-width: 768px) {
      padding: 16px 32px 32px;
    }
  }
}

.info-modal {
  &-content {
    min-width: 300px;
    max-height: 228px;
    padding: 8px 24px 16px;
    overflow-y: auto;
  }

  &-li {
    font-family: 'Barlow';
    font-size: 1rem;
    text-transform: none;
    line-height: 1.7;

    &::before {
      font-size: 0.875rem;
      margin-right: 8px;
    }
  }
}

.crop-container {
  height: 300px;
  border-bottom: 1px solid var(--neutral-300-color);
  padding-top: 32px;

  .crop-content {
    position: relative;
    height: calc(100% - 64px);
  }

  div[data-testid='cropper'] {
    color: var(--neutral-S08-color);
  }

  .controls {
    position: relative;
    width: 50%;
    padding: 24px 0 8px;
    margin: 0 auto;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      height: 100%;
      font-family: 'icon-font' !important;
      font-size: 1.2rem;
      line-height: 30px;
      padding: 24px 0 8px;

      @media (pointer: coarse) {
        line-height: 44px;
      }
    }

    &::before {
      content: '\e922';
      left: -32px;
    }

    &::after {
      content: '\e921';
      right: -32px;
    }
    // Overwrite stile of react-easy-crop
    .MuiSlider-root,
    .MuiSlider-rail,
    .MuiSlider-track {
      height: 4px;
      border-radius: 2px;
    }
    .MuiSlider-root {
      color: var(--primary-color);
    }
    .MuiSlider-rail {
      background-color: var(--neutral-300-color);
    }
    .MuiSlider-thumb {
      width: 16px;
      height: 16px;
      margin-top: -6px;
    }
  }
  @media screen and (min-width: 768px) {
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-height: 300px;
    height: auto;
  }
}

.add-to-profile-form {
  width: 100%;
  padding: 8px 16px 0;

  @media screen and (min-width: 768px) {
    width: 400px;
    padding: 8px 32px 0;
  }
}

.modal-inputs-wrapper {
  padding: 8px 16px 0;

  .input-group:not(:last-child) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    padding: 8px 24px 0;
  }
}

.scrollable-wrapper {
  height: calc(100% - 67px);
  overflow-y: auto;

  @media screen and (min-width: 768px) {
    @include flex;
    @include space-between;
    @include flex-column;
  }
}
// Edit our projects
.edit-projects-wrapper {
  display: block;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 769px) {
    height: auto;
  }

  @media screen and (min-width: 901px) {
    @include flex;
    width: auto;
  }
}
.existing-projects {
  display: block;
  width: 100%;
  height: 100%;
  background: var(--neutral-100-color);
  overflow-y: auto;

  li {
    @include transition(var(--transition-default));
    position: relative;
    font-size: 1rem;
    line-height: 1.75;
    padding: 8px 40px 8px 16px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 16px;
      right: 0;
      display: block;
      height: 1px;
      background: var(--neutral-300-color);
    }

    .btns-wrapper {
      position: absolute;
      top: 0;
      right: 16px;
      height: 100%;

      button {
        height: 100%;
        color: var(--primary-color);
        padding: 0;
        margin: 0 4px;
        &:hover {
          color: var(--secondary-color);
        }
      }
    }

    &:hover {
      background: var(--neutral-200-color);
    }
  }

  @media screen and (min-width: 769px) {
    width: 400px;
    min-height: 345px;
    max-height: 480px;

    li {
      .btns-wrapper {
        opacity: 0;
      }
      &:hover {
        .btns-wrapper {
          opacity: 1;
        }
      }
    }
  }
}

.add-project-btn-wrapper {
  @include flex;
  @include justify-center;
  @include flex-align-center;
  width: 400px;
  min-height: 345px;
}

// Confirmation
.confirmation {
  &-content {
    width: 100%;
    height: calc(100% - 96px); // 96px - height of .modal-heading with subtitle
  }

  &-buttons-wrapper {
    @include flex;
    @include flex-column;
    @include justify-center;
    position: relative;
    height: 100%;
    padding: 24px 16px 32px;

    > button:first-child {
      margin-bottom: 24px;
    }

    .error {
      position: absolute;
      top: 0;
    }
  }

  @media screen and (min-width: 769px) {
    &-content {
      width: 552px;
      height: auto;
    }

    &-buttons-wrapper {
      @include display-flex-end;
      @include flex-row;
      padding: 24px 32px 32px;

      > button:first-child {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
  }
}

.mail-sent {
  @include flex;
  @include flex-column;
  @include justify-center;
  @include flex-align-center;
  position: relative;
  max-width: 100%;
  width: 800px;
  text-align: center;
  padding: 32px;

  img {
    width: 100px;
    height: 100px;
  }

  * {
    padding: 16px 0;
  }

  .icon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 8px;
  }
}

.map-container {
  width: 100%;
  height: 300px;
}

.footer-btns-wrapper {
  @include flex;
  @include display-flex-end;
  padding: 24px 32px 32px;
}

.invoices-content {
  width: 100%;
  height: calc(100% - 72px); // 72px - height of .modal-heading
  padding: 8px 24px;
  overflow-y: auto;

  li {
    @include flex;
    position: relative;
    border-radius: 4px;
    border: 1px solid var(--neutral-300-color);
    padding-left: 48px;
    margin-bottom: 8px;

    &::before {
      position: absolute;
      top: 16px;
      left: 16px;
      font-size: 1.4rem;
      color: var(--neutral-500-color);
    }
  }

  .invoice-info {
    width: calc(100% - 56px); // 56px - width of .download-btn-wrapper
    padding: 16px 0;

    > div {
      line-height: 1.7;
    }

    .label {
      color: var(--neutral-500-color);
      margin-right: 8px;
    }
  }

  .download-btn-wrapper {
    padding: 8px;

    button {
      width: 40px;
      height: 40px;

      &::before {
        font-size: 1.6rem;
        color: var(--neutral-500-color);
      }
    }
  }

  @media screen and (min-width: 501px) {
    .invoice-info {
      @include flex;

      > div {
        width: 50%;
        line-height: 1.2;
      }
    }
  }

  @media screen and (min-width: 769px) {
    width: 552px;
    height: 400px;
  }
}

.invoices-button-wrapper {
  display: none;

  @media screen and (min-width: 769px) {
    display: block;
    text-align: right;
    padding: 24px 32px 32px;
    border-top: 1px solid var(--neutral-300-color);
    > button {
      margin: 0 auto;
    }
  }
}

.radio-btns-list {
  min-width: 320px;
  width: 100%;

  li {
    border-bottom: 1px solid var(--neutral-300-color);
    padding: 0 16px;

    label {
      display: inline-block;
      width: 100%;
      line-height: 1.9;
    }
  }
}

.bordered-block {
  position: relative;
  padding-right: 32px;
  margin-bottom: 16px;
  cursor: pointer;

  strong,
  .icon-arrow-right {
    color: var(--primary-color);
  }
  .icon-arrow-right {
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 0;
    padding: 0;
  }
  strong {
    display: inline-block;
    padding-bottom: 8px;
  }

  &:hover {
    strong,
    .icon-arrow-right {
      color: var(--secondary-color);
    }
  }

  @media screen and (min-width: 769px) {
    border-radius: 4px;
    border: 1px solid var(--neutral-300-color);
    padding: 8px 80px 8px 16px;

    .icon-arrow-right {
      right: 32px;
    }
  }
}

.sign-up-links-wrapper {
  width: 100%;
  height: calc(100% - 240px);
  // 128px - height of .sign-up-links-footer, 112px - height of .modal-heading with subtitle
  padding: 24px 24px 0;

  @media screen and (min-width: 769px) {
    width: 552px;
    height: auto;
  }
}

.sign-up-links-footer {
  padding: 24px;
  border-top: 1px solid var(--neutral-300-color);

  a {
    width: 100%;
    margin-top: 16px;
  }

  @media screen and (min-width: 481px) {
    @include flex;
    @include space-between;
    @include flex-align-center;

    a {
      width: auto;
    }
  }
}

// Create/Edit list
.create-list-form {
  @extend .add-to-profile-form;
  max-height: calc(100% - 67px); // 67px - width of .modal-heading
  overflow-y: auto;

  &.wide {
    @media screen and (min-width: 768px) {
      width: 436px;
    }
  }

  &.large {
    @media screen and (min-width: 768px) {
      width: 552px;
    }
  }

  &__btn-wrapper {
    @include flex;
    @include display-flex-end;
    position: relative;
    padding-bottom: 24px;

    .btn-minimal {
      position: absolute;
      left: 0;
      top: 16px;
      font-family: 'Barlow-Medium';
      font-size: 0.875rem;
      text-decoration: underline;
    }
  }

  textarea {
    min-height: 112px;
    max-height: 40vh;
  }

  .edit-item-list {
    @include flex;
    @include flex-align-center;
    width: 100%;
    border: 1px solid var(--neutral-300-color);
    border-radius: 2px;
    padding: 16px;
    margin: 8px 0;

    &__img-wrapper {
      width: 80px;
      height: 80px;

      img {
        width: 100%;
        height: auto;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }

    h3 {
      width: calc(100% - 80px); // 80px - width of image
      padding-left: 16px;
      word-break: break-word;
    }
  }
}

.simple-warning {
  font-size: 0.875rem;
  border: 1px solid var(--error-100-color);
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 16px;

  &::before {
    font-size: 1.2rem;
    color: var(--error-500-color);
    margin-right: 8px;
    vertical-align: -4px;
  }
}

.leave-review-info {
  @include flex;
  @include flex-align-center;

  &__image {
    width: 40px;
    height: 40px;

    img,
    div {
      width: 40px;
      height: 40px;
    }

    div {
      background: var(--neutral-200-color);
    }
  }

  &__main {
    width: calc(100% - 40px); // 40px - width of .leave-review-info__image
    line-height: 1.4;
    padding-left: 8px;

    div:first-child {
      @include ellipsis-text;
      font-family: 'Barlow-Medium';
      font-size: 0.875rem;
      text-transform: uppercase;
    }

    div:last-child {
      font-size: 0.652rem;
      color: var(--neutral-500-color);
    }
  }
}
