.info-tooltip {
  display: inline-block;

  &.relative {
    position: relative;
  }

  &-body {
    position: absolute;
    display: none;
    background-color: var(--neutral-color);
    box-shadow: var(--box-shadow-default);

    &.open {
      top: -24px;
      left: 0;
      display: block;
      width: 90vw;
      z-index: 1;
    }
  }

  &-heading {
    @include flex;
    @include space-between;
    @include flex-align-center;
    padding: 24px;
    border-bottom: 1px solid var(--neutral-300-color);

    .icon-close:hover {
      color: var(--secondary-color);
    }
  }

  &-content {
    max-height: 228px;
    padding: 8px 24px 16px;
    overflow-y: auto;
  }

  &-li {
    font-family: 'Barlow';
    font-size: 1rem;
    text-transform: none;
    line-height: 1.7;

    &::before {
      font-size: 0.875rem;
      margin-right: 8px;
    }
  }

  @media screen and (min-width: 601px) {
    &-body {
      &.open {
        left: 24px;
        width: auto;

        ul {
          white-space: nowrap;
        }
      }
    }

    &-li {
      white-space: nowrap;
    }
  }
}
