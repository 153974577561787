.show-more {
  width: 100%;
  text-align: center;
}

.collapsible-block {
  &__content {
    height: 0;
    overflow: hidden;

    &.open {
      height: auto;
      overflow: unset;
    }
  }
}
