.icon-arrow {
  position: absolute;
  right: 16px;
  font-size: 0.3rem;
  margin-left: 4px;

  &::before {
    @include transition(var(--transition-default));
    display: inline-block;
    font-family: 'icon-font' !important;
    content: '\e90b';
  }

  &.rotate {
    &::before {
      @include transform(rotate(-180deg));
      padding-top: 2px;
    }
  }

  @media screen and (min-width: 481px) {
    position: static;
  }
  @media screen and (min-width: 1025px) {
    &::before {
      content: '\e900';
      font-size: 0.6rem;
    }
  }
}
