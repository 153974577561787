.confidentiality {
  @include container-width(1126px);
  @include flex;
  @include flex-wrap;
  padding: 16px 0 24px;

  &-heading {
    text-align: right;
    padding: 16px 16px 0;
  }

  &-content {
    padding: 0 16px;
    h3,
    p {
      line-height: 1.7;
    }
    strong {
      display: inline-block;
      padding-bottom: 8px;
    }
    h3 {
      padding: 16px 0 8px;
    }
    p {
      padding-bottom: 8px;
    }
    ul {
      li {
        font-size: 0.875rem;
        padding: 4px 0;
      }
    }
  }

  @media screen and (min-width: 769px) {
    padding: 32px 0;

    &-heading {
      width: 48%;
      font-size: 2.125rem;
    }

    &-content {
      width: 52%;
    }

    ul {
      li {
        font-size: 1rem;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    padding: 64px 0;

    &-heading {
      padding: 16px 2.5rem 0 0;
    }

    &-content {
      padding: 0;
    }
  }
}
