@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  -o-transform: $property;
  transform: $property;
}

@mixin transition($property) {
  -webkit-transition: $property;
  -o-transition: $property;
  transition: $property;
}

@mixin animation($property) {
  -webkit-animation: $property;
  animation: $property;
}

@mixin animation-delay($property) {
  -webkit-animation-delay: $property;
  animation-delay: $property;
}

@mixin box-shadow($property) {
  -webkit-box-shadow: $property;
  box-shadow: $property;
}

@mixin ellipsis-text {
  width: 100%;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin container-width($property) {
  width: 100%;
  max-width: $property;
  margin: 0 auto;
}

@mixin flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin space-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

@mixin justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin display-flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@mixin flex-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin align-flex-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@mixin flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.default-transition {
  -webkit-transition: var(--transition-default);
  -o-transition: var(--transition-default);
  transition: var(--transition-default);
}

.flex {
  @include flex;
}

.space-between {
  @include space-between;
}

.space-around {
  @include space-around;
}

.flex-align-center {
  @include flex-align-center;
}

.align-flex-end {
  @include align-flex-end;
}

.justify-center {
  @include justify-center;
}

.display-flex-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.display-flex-end {
  @include display-flex-end;
}

.flex-column {
  @include flex-column;
}

.flex-row {
  @include flex-row;
}

.flex-wrap {
  @include flex-wrap;
}

.flex-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
