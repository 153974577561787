.white-background-page {
  min-height: calc(100vh - 264px);
  background: var(--neutral-color);
  padding: 16px;
}
.tabs-wrapper {
  @include container-width(1126px);

  .tab-grid {
    padding: 24px 0;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: 1rem;

    @media screen and (min-width: 769px) {
      -ms-grid-columns: minmax(0, 1fr) 1rem minmax(0, 1fr);
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 1rem;
    }
  }
}

.tabs-list {
  @include flex;
  border-bottom: 2px solid var(--neutral-200-color);

  li {
    @include transition(var(--transition-default));
    bottom: -2px;
    width: 50%;
    font-family: 'Barlow-Semibold';
    font-size: 1rem;
    color: var(--neutral-500-color);
    text-align: center;
    line-height: 64px;
    background: transparent;
    border: none;
    padding: 0;

    &::after {
      @include transition(var(--transition-default));
      display: block;
      content: '';
      width: 0;
      height: 2px;
      background: var(--primary-color);
      margin: 0 auto;
    }

    &:hover {
      color: var(--primary-color);
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.react-tabs__tab--selected {
      color: var(--primary-color);

      &::after {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 481px) {
    li {
      width: 200px;
      margin-right: 24px;
    }
  }
}

.brand-item-card {
  @include box-shadow(var(--box-shadow-default));
  width: 100%;
  background: var(--neutral-color);

  &__info {
    @include flex;
    @include flex-align-center;
    padding: 32px;

    img,
    .empty-image {
      @include flex-auto;
      width: 80px;
      height: 80px;
      -o-object-fit: contain;
      object-fit: contain;
    }

    .empty-image {
      background: var(--neutral-200-color);
    }
  }

  &__details {
    width: calc(100% - 80px); // 80px - width of img of.empty-image
    padding-left: 16px;

    h3 {
      word-break: break-word;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow-wrap: break-word;
      -webkit-box-orient: vertical;
      max-height: 3em;
      min-height: 18px;
    }
  }

  &__location {
    font-size: 1rem;
    color: var(--neutral-500-color);
    line-height: 1.4;
  }

  &__link-wrapper {
    @include transition(var(--transition-default));
    width: 100%;
    text-align: center;
    background-color: var(--primary-color);
    cursor: pointer;

    &:hover {
      background: var(--secondary-color);
    }

    a {
      display: block;
      font-family: 'Barlow-Medium';
      font-size: 0.785rem;
      color: var(--neutral-color);
      text-transform: uppercase;
      line-height: 40px;
    }

    .icon-link-external {
      font-size: 1rem;
      margin-left: 8px;
      vertical-align: -2px;
    }
  }
}
